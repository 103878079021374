import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import conditionalCSS from './styles/LandingSection';

function LandingSection() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();
  return (
    <Box className={classes.container}>
      <Box className={classes.leftArea}>
        <Typography variant="h3" className={classes.eziostatHeading}>
          EzioStat
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          Connect & Visualize Your IoT device
        </Typography>
        <Link to="/login" className={classes.CTALogin}>
          <Button
            color="primary"
            tabIndex="-1"
            variant="contained"
            disableElevation
          >
            Visualize Now!
          </Button>
        </Link>
      </Box>
      <Box>
        <img
          src="/assets/images/app-home-screen.png"
          className={classes.appScreenImage}
          alt=""
        />
      </Box>
    </Box>
  );
}

export default LandingSection;
