import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles(() => ({
    loadingBar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 2,
      zIndex: 1000000,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
