import React, { useEffect, useState } from 'react';
import { Toolbar, AppBar, Box, Typography, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu as MenuIcon } from '@material-ui/icons';
import conditionalCSS from './styles/Navigation';
import Menu from './Menu';
import MainSubNavigation from './MainSubNavigation';
import SingleDeviceNavigation from './SingleDeviceNavigation';

export default function Navigation({ homePage, appBarClass, rootClass, children }) {
  const location = useLocation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [showMainSubNavigation, setShowMainNavigation] = useState(false);
  const [showSingleDeviceNavigation, setSingleDeviceNavigation] = useState(false);
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const classes = conditionalCSS(homePage, xs)();

  useEffect(() => {
    let path = location.pathname;

    // Main Sub Navigation
    const inclusionsForMainSubNavigation = ['/devices', '/try', '/download'];

    if (path.endsWith('/')) {
      path = path.substr(0, path.length - 1)
    }

    for (let i = 0; i < inclusionsForMainSubNavigation.length; i += 1) {
      const item = inclusionsForMainSubNavigation[i];
      if (path.endsWith(item)) {
        setShowMainNavigation(true);
        break;
      } else {
        setShowMainNavigation(false);
      }
    }

    // Single Device Navigation

    const regex = /\/devices\/(([\da-f]{2}[:-]){5}[\da-f]{2})\/\w+/

    if (regex.test(path)) {
      setSingleDeviceNavigation(true);
    } else {
      setSingleDeviceNavigation(false);
    }

  }, [location.pathname])


  return (
    <Box className={[classes.root, rootClass || ''].join(' ')}>
      <AppBar elevation={0} className={[classes.appBar, appBarClass || ''].join(' ')}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.leftArea}>
            {showMainSubNavigation || showSingleDeviceNavigation ? <>
              <Button className={classes.menuButton} onClick={() => setNavigationExpanded(!navigationExpanded)}>
                <MenuIcon className={classes.menuIcon} />
              </Button>
            </> : null}
            <Link to="/" className={classes.homePageLink}>
              <img
                src="/assets/images/logo.png"
                className={classes.logo}
                alt="Logo"
              />
              {!homePage && !xs ? (
                <Typography className={classes.eziostatText}>
                  EzioStat
                </Typography>
              ) : null}
            </Link>
          </Box>
          <Menu classes={classes} />
        </Toolbar>
      </AppBar>
      <Box className={classes.contentContainer}>
        <MainSubNavigation expanded={navigationExpanded} show={showMainSubNavigation}>
          {children}
        </MainSubNavigation>
        <SingleDeviceNavigation expanded={navigationExpanded} show={showSingleDeviceNavigation}>
          {children}
        </SingleDeviceNavigation>
        {!showMainSubNavigation && !showSingleDeviceNavigation ? children : null}
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  homePage: PropTypes.bool,
  appBarClass: PropTypes.string,
  rootClass: PropTypes.string,
  children: PropTypes.node.isRequired
};
Navigation.defaultProps = {
  homePage: false,
  appBarClass: '',
  rootClass: '',
};
