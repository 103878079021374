import React from 'react'
import Navigation from '../../components/Navigation'
import Map from '../../components/LiveTracker/Map';

function LiveTracker() {
  return (
    <>
      <Navigation>
        <Map />
      </Navigation>
    </>
  )
}

export default LiveTracker
