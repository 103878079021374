import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    menu: {
      display: 'flex',
      gap: 20,
      alignItems: 'center',
    },
    anchorBtn: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 20,
    },
    button: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.contrastText,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
