import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    downloadContainer: {
      width: `100%`,
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      padding: 20,
      background: theme.palette.background.default
    },
    paperBox: {
      padding: 20,
    },
    formControl: {
      minWidth: 180,
      margin: '0px 5px'
    },
    divider: {
      margin: '20px 0px'
    },
    inputPicker: {
      margin: '0px 5px'
    },
    inputFieldsAndDownloadButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    inputFieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
    },
    button : {
      width: 40,
      height: 40,
      minWidth: 'unset',
      borderRadius: '50%',
    },
  }));
  return useStyles;
};

export default conditionalCSS;
