import firebase from 'firebase/app';

export const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  signInSuccessUrl: '/devices',
};

export const selectableTimeRanges = [
  {
    label: 'Custom',
    value: 0,
  },
  {
    label: '15 Minutes',
    value: 15, // value must be in minutes and integer (Number)
  },
  {
    label: '30 Minutes',
    value: 30,
  },
  {
    label: '45 Minutes',
    value: 45,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '1 Hour 30 minutes',
    value: 90,
  },
  {
    label: '2 Hours',
    value: 120,
  },
];


export const sensors = {
  'pm2_5' : "PM 2.5",
  'pm1_0' : "PM 1.0",
  'pm10' : "PM 10",
  'temp' : "Temperature",
  'humid' : "Humidity",
  'ps' : "Pressure",
  'co2': 'CO2',
  "pm1_0_x" : "_PM 1.0",
  "pm2_5_x" : "_PM 2.5",
  "pm10_x" : "_PM 10",
}

export const genericTimeFormat = "yyyy/MM/dd hh:mm a";


export const allEndpoints = {
  'all-devices': {
    target:
      'https://firestore.googleapis.com/v1/projects/eziostat/databases/(default)/documents/users/[YOUR_USER_ID]/devices',
    variables: {
      'User ID': {
        name: 'YOUR_USER_ID',
        type: 'local',
      },
    },
    method: 'GET',
    http: 'HTTP/1.1',
    contentType: 'application/json',
  },
  'single-device-data': {
    target:
      'https://firestore.googleapis.com/v1/projects/eziostat/databases/(default)/documents/devices/[DEVICE_MAC_ID]/data',
    variables: {
      "Device's Mac ID": {
        name: 'DEVICE_MAC_ID',
        type: 'input',
      },
    },
    method: 'GET',
    http: 'HTTP/1.1',
    contentType: 'application/json',
  },
  'location-history': {
    target:
      'https://firestore.googleapis.com/v1/projects/eziostat/databases/(default)/documents/devices/[DEVICE_MAC_ID]/loc_history',
    variables: {
      "Device's Mac ID": {
        name: 'DEVICE_MAC_ID',
        type: 'input',
      },
    },
    method: 'GET',
    http: 'HTTP/1.1',
    contentType: 'application/json',
  },
}
export default undefined;
