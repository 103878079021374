import subMinutes from 'date-fns/subMinutes';
import subHours from 'date-fns/subHours';
import subSeconds from 'date-fns/subSeconds';
import DateFNSFormat from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import {genericTimeFormat} from '../constants';
// constants
export const DATE_FORMATTING_STRING = 'yyyy-MM-dd';

export const getTodayDate = (format) =>
  DateFNSFormat(new Date(), format || DATE_FORMATTING_STRING);

export const formatDate = (date, format) =>
  DateFNSFormat(new Date(date), format || DATE_FORMATTING_STRING);

export const formatTime = (date = Date.now(), format) => {
  if (format) {
    return DateFNSFormat(new Date(date), format);
  }
  return formatISO(date);
};
export const readableTimeFormat = (time) => formatTime(time, genericTimeFormat)

export const subTime = (time, subDuration, format) => {
  const [subtractTime, unit] = subDuration.split(' ');
  let subtractor;
  switch (unit) {
    case 'minutes':
      subtractor = subMinutes;
      break;
    case 'minute':
      subtractor = subMinutes;
      break;
    case 'hour':
      subtractor = subHours;
      break;
    case 'hours':
      subtractor = subHours;
      break;
    case 'seconds':
      subtractor = subSeconds;
      break;
    case 'second':
      subtractor = subSeconds;
      break;
    default:
      break;
  }
  if (subtractor) {
    if (format) {
      return DateFNSFormat(subtractor(parseISO(new Date(time).toISOString()), +subtractTime), format);
    }
    const subtime = subtractor(parseISO(new Date(time).toISOString()), +subtractTime);
    return formatISO(subtime);
  }
  return undefined;
};
export const formatToUNIX = (time) => getUnixTime(new Date(time)) * 1000;
export const formatToISO = (time) => formatISO(new Date(time));
