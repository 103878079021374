import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles(() => ({
    urlInputCard: {
      marginTop: 20,
      padding: 10,
      display: 'flex',
      gap: 20,
    },
    urlInputField: {
      width: '100%',
    },
  }));
  return useStyles;
};

export default conditionalCSS;
