import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      background: theme.palette.white,
    },
    loginPaper: {
      width: '90%',
      maxWidth: 350,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    logo: {
      width: 150,
      height: 150,
    },
    signInBox: {
      marginTop: 50,
    },
    infoText: {
      fontSize: 12,
      textAlign: 'center',
    },
  }));
  return useStyles;
};
export default conditionalCSS;
