import { Box, Button, Card, CardContent, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForward, Dns, Fingerprint, GetApp, LocationOn } from '@material-ui/icons';
import React from 'react';
import conditionalCSS from './styles/EzioStatWebPortalSection';
import conditionalCSSListDevices from '../../components/Devices/styles/ListDevices';

function EzioStatWebPortalSection() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = { ...conditionalCSSListDevices()(), ...conditionalCSS(xs)(), };
  return (
    <Box className={classes.container}>
      <Box className={classes.leftArea}>
        <Typography variant="h4">EzioStat Web</Typography>
        <Typography variant="h6" className={classes.webAppSubTitle}>
          View, Manage, Track All Your Devices!
        </Typography>
        <Box className={classes.webAppSteps}>
          <Typography className={classes.webAppStepsText}>Login to Web App</Typography>
          <Typography className={classes.webAppStepsText}>Go To My Devices</Typography>
          <Typography className={classes.webAppStepsText}>Choose A Device</Typography>
          <Typography className={classes.webAppStepsText}>View The Data</Typography>
        </Box>
        <Button disableElevation className={classes.loginButton} color="primary" variant="contained">
          Login Now!
        </Button>
      </Box>

      <Box className={classes.rightArea}>
        <Card className={[classes.card, classes.demoCard].join(' ')}>
          <CardContent>
            <Box className={classes.linkContainer}>
              <Box className={classes.cardTitleDescriptionAndHistory}>
                <Box>
                  <Box className={classes.deviceItemBox}>
                    <Dns className={classes.deviceItemIcon} />
                    <Typography className={classes.deviceName}>IIT to Home</Typography>
                  </Box>
                  <Box className={classes.deviceItemBox}>
                    <Fingerprint className={classes.deviceItemIcon} />
                    <Typography className={classes.deviceMacID} color="textSecondary">
                      9c:9c:1f:ef:b7:48
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.locationBox}>
                <LocationOn />
                <Typography className={classes.deviceLocationText}>3rd Floor, Synergy Building, IIT Campus, Indian Institute of Technology Delhi, Hauz Khas, New Delhi, Delhi 110016, India</Typography>
              </Box>
            </Box>
            <Box className={classes.coordinatesBox}>
              <Button disableRipple>
                <Box className={classes.coordinatesSingleBox}>
                  <Typography className={classes.smallText}>Lat</Typography>
                  <Typography className={classes.smallText}>28.5435412</Typography>
                </Box>
              </Button>
              <Button disableRipple>
                <Box
                  className={classes.coordinatesSingleBox}
                >
                  <Typography className={classes.smallText}>Lng</Typography>
                  <Typography className={classes.smallText}>77.1911209</Typography>
                </Box>
              </Button>
            </Box>
            <Box className={classes.cardButtonBox}>
              <Box className={classes.locationAndDownloadBox}>
                <Button color="primary" disableFocusRipple disableTouchRipple disableRipple variant="contained" className={[classes.cardButton, classes.demoButton].join(' ')} disableElevation>
                  <LocationOn />
                </Button>
                <Button color="primary" disableFocusRipple disableTouchRipple disableRipple variant="contained" className={[classes.cardButton, classes.demoButton].join(' ')} disableElevation>
                  <GetApp />
                </Button>
              </Box>
              <Button color="primary" disableFocusRipple disableTouchRipple disableRipple variant="contained" className={[classes.cardButton, classes.demoButton].join(' ')} disableElevation>
                <ArrowForward />
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default EzioStatWebPortalSection;
