import React, { useState, useEffect, useContext } from 'react';

import Navigation from '../../components/Navigation';
import ListDevices from '../../components/Devices/ListDevices';
import DevicesDataError from '../../components/Devices/DevicesDataError';
import { DevicesListCtx } from '../../components/wrappers/Contexts/states/DevicesCtx';
import { getAllDevices } from '../../tools/functions/firestore';
import { AuthContext } from '../../components/wrappers/Authentication';


export default function Devices() {
  const [dataStatus, setDataStatus] = useState('');
  const [fetchedDevicesList, setFetchedDevicesList] = useState();
  const { setDevicesList, devicesList, } =
    useContext(DevicesListCtx);
  const { details } = useContext(AuthContext);
  useEffect(() => {
    if (devicesList === undefined) {
      setDataStatus('Loading...');
    } else if (!devicesList?.length) {
      setDataStatus('No devices found...');
    } else {
      setDataStatus('');
    }
  }, [devicesList]);

  useEffect(() => {
    (async () => {
      if (details && !devicesList && !devicesList?.length) {
        getAllDevices(details).then(setFetchedDevicesList);
      }
    })();
  }, [details, devicesList]);

  useEffect(() => {
    if (fetchedDevicesList) {
      console.log('updating...');
      setDevicesList(fetchedDevicesList)
    }
  }, [fetchedDevicesList, setDevicesList])

  return (
    <>
      <Navigation>
        <ListDevices />
      </Navigation>
      {dataStatus.length ? <DevicesDataError text={dataStatus} /> : null}
    </>
  );
}
