import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles(() => ({
    detailsContainer: {
      padding: `20px ${xs ? '10px' : '35px'}`,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 20,
    },
    card: {
      minWidth: 200,
      maxWidth: 350,
    },
    cardAnchor: {
      textDecoration: 'none',
    },
    deviceName: {
      fontWeight: 800,
    },
    deviceMacID: {
      margin: '5px 0px',
      fontSize: '14px',
    },
    locationBox: {
      marginTop: 20,
      display: 'flex',
      gap: 10,
    },
    deviceLocationText: {
      fontSize: 14,
    },
    deviceDetailsAndHistory: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));
  return useStyles;
};
export default conditionalCSS;
