import React from 'react';
import { Drawer, List, ListItem, Tooltip, Box, ListItemIcon, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Dashboard, GetApp, Timeline } from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import conditionalCSS from './styles';

function SingleDeviceNavigation({ children, expanded, show }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(expanded, xs)();
  const { deviceId, route } = useParams();


  if (!show) return null;
  return (
    <Box>
      <Drawer variant="permanent" PaperProps={{ className: classes.drawerPaper }} className={classes.mainDrawer}>
        <List>
          <ListItem className={classes.listItem}>
            <Link to={`/devices/${deviceId}/dashboard`} className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Device's Dashboard" placement="right">
                  <Dashboard className={[classes.navItemIcon, (route === "dashboard" ? classes.iconActive : '')].join(' ')} />
                </Tooltip>
              </ListItemIcon>
              {expanded ? <Typography className={[classes.listItemText, (route === "dashboard" ? classes.activeText : '')].join(' ')}>
                Dashboard
              </Typography> : null}
            </Link>
          </ListItem>


          <ListItem className={classes.listItem}>
            <Link to={`/devices/${deviceId}/visualize`} className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Visualize" placement="right">
                  <Timeline className={[classes.navItemIcon, (route === "visualize" ? classes.iconActive : '')].join(' ')} />
                </Tooltip>
              </ListItemIcon>
              {expanded ? <Typography className={[classes.listItemText, (route === "visualize" ? classes.activeText : '')].join(' ')}>Visualize</Typography> : null}
            </Link>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Link to={`/download?id=${deviceId}`} className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Download" placement="right">
                  <GetApp className={classes.navItemIcon} />
                </Tooltip>
              </ListItemIcon>
              {expanded ? <Typography className={classes.listItemText}>Download</Typography> : null}
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <Box className={classes.siteContentContainer}>
        {children}
      </Box>
    </Box>
  )
}

SingleDeviceNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  show: PropTypes.bool.isRequired,
}

SingleDeviceNavigation.defaultProps = {
  expanded: false,
}

export default SingleDeviceNavigation


/*

*/