import { LinearProgress } from '@material-ui/core';
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoaderCtx = createContext();
const Loader = ({ children }) => {
  const [loadersState, setLoaderState] = useState(false);

  return (
    <LoaderCtx.Provider
      value={{ state: loadersState, changeLoaderState: setLoaderState }}
    >
      <LinearProgress
        hidden={!loadersState}
        variant="indeterminate"
        color="secondary"
        style={{
          position: 'absolute',
          width: '100%',
          height: '2px',
          top: 0,
          zIndex: '999999',
        }}
      />
      {children}
    </LoaderCtx.Provider>
  );
};
Loader.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Loader;
