import { LinearProgress } from '@material-ui/core';

import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { firebaseAuth } from '../../tools/firebase';
import conditionalCSS from './styles/Authentication';

export const AuthContext = createContext();

export default function Authentication({ children }) {
  const [userSignedIn, setUserSignedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [userState, setUserState] = useState(undefined);
  const [userToken, setUserToken] = useState(undefined);
  const classes = conditionalCSS()();
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((authenticatedUserState) => {
      setUserSignedIn(Boolean(authenticatedUserState));
      authenticatedUserState.getIdToken().then((token) => {
        setUserToken(token);
      });
      setUser(authenticatedUserState);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userSignedIn) {
      setUserState('authenticated');
    } else if (userSignedIn === false) {
      setUserState('unauthenticated');
    } else {
      setUserState('loading');
    }
  }, [userSignedIn]);

  const values = {
    authenticated: userSignedIn,
    state: userState,
    details: !user ? null : { docId: user.uid, ...user.providerData[0], token: userToken },
  };
  return (
    <AuthContext.Provider value={values}>
      {userSignedIn === null ? <LinearProgress color="primary" className={classes.loadingBar} /> : null}
      {children}
    </AuthContext.Provider>
  );
}
Authentication.propTypes = {
  children: PropTypes.node.isRequired,
};
