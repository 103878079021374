import { Box, Button, Paper, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import conditionalCSS from './styles';
import getDataPointColorInformation from '../../../tools/functions/getDataPointColorInformation';
import { formatTime } from '../../../tools/functions/timeFormatting';

function DataPointToolBox({ point }) {
  const classes = conditionalCSS()()
  const [value, setValue] = useState();
  const [valueInfo, setValueInfo] = useState();

  useEffect(() => {
    setValue(point);
    if (point) setValueInfo(getDataPointColorInformation(point.pm2_5));
  }, [point]);

  const closeToolbox = () => {
    setValue();
  }

  if (value) {
    return <>
      <Paper className={classes.paper}>
        <Box className={classes.firstRow}>
          <Box className={classes.pointContainer}>
            <Box className={classes.pointBox}>
              <Box className={classes.pointColorBox} style={
                {
                  background: valueInfo && valueInfo.color,
                }} />
              <Box className={classes.pointTextBox}>
                <Typography className={classes.pointText}>
                  {value && value.pm2_5}
                </Typography>
                <Typography className={classes.unitText}>μg/m3</Typography>
              </Box>
            </Box>
            <Typography className={classes.sensorName}>PM 2.5</Typography>

            <Typography>
              {value ? formatTime(value.createdAt, 'dd - MM - yyyy | hh:mm:ss a') : ''}
            </Typography>
            <Typography className={classes.macIDText}>
              {value ? `MAC ID: ${value.id}` : ''}
            </Typography>
          </Box>
          <Button onClick={closeToolbox} className={classes.closeButton}>
            <Close />
          </Button>
        </Box>
      </Paper>
    </>
  };
  return null
}

DataPointToolBox.propTypes = {
  point: PropTypes.shape({
    pm2_5: PropTypes.number
  })
}
DataPointToolBox.defaultProps = {
  point: undefined
}
export default DataPointToolBox
