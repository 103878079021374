import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Redirect } from 'react-router-dom';
import { firebaseAuth } from '../../tools/firebase';

import { uiConfig } from '../../tools/constants';
import conditionalCSS from './styles/login';
import { AuthContext } from '../../components/wrappers/Authentication';

export default function Login() {
  const classes = conditionalCSS()();
  const { authenticated } = useContext(AuthContext);
  return (
    <Box className={classes.container}>
      <img src="/assets/images/logo.png" alt="" className={classes.logo} />
      <Box className={classes.signInBox}>
        {authenticated ? (
          <Redirect to="/devices" />
        ) : (
          <>
            <Typography color="textSecondary" className={classes.infoText}>
              Press the button below to proceed
            </Typography>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebaseAuth}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
