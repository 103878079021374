import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from '../components/wrappers/PrivateRoute';
import Devices from '../pages/Devices';
import History from '../pages/History';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Logout from '../pages/Logout';
import SingleDevice from '../pages/SingleDevice';
import TryItAPI from '../pages/TryItAPI';
import Download from '../pages/Download';
import LiveTracker from '../pages/LiveTracker';

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <PrivateRoute path="/devices" component={Devices} exact />
        <PrivateRoute path="/devices/:deviceId/:route" exact component={SingleDevice} />
        <PrivateRoute path="/history/:deviceId" component={History} />
        <PrivateRoute path="/try" component={TryItAPI} />
        <PrivateRoute path="/download" component={Download} />
        <PrivateRoute path="/live" component={LiveTracker} />
      </Switch>
    </Router>
  );
}

