import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    downloadFab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  }));
  return useStyles;
};
export default conditionalCSS;
