import { Avatar, Box, Button, Menu as MuiMenu, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AuthContext } from '../wrappers/Authentication';
import conditionalCSS from './styles/Accounts';

export default function Accounts({ userDetails }) {
  const { authenticated } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(null);
  const classes = conditionalCSS()();

  const hideMenuFunction = () => setShowMenu(null);
  const showMenuFunction = (event) => setShowMenu(event.currentTarget);

  if (!userDetails) return null;
  return (
    <>
      {authenticated ? (
        <Button onClick={showMenuFunction} centerRipple>
          <Avatar src={userDetails.photoURL} alt="Your Profile Image" />
        </Button>
      ) : (
        <Avatar />
      )}
      <MuiMenu
        id="profile-menu"
        anchorEl={showMenu}
        keepMounted
        open={Boolean(showMenu)}
        onClose={hideMenuFunction}
        PaperProps={{ className: classes.accountsMenu }}
      >
        <Box className={classes.menuBox}>
          <img src={userDetails.photoURL} alt="" className={classes.userProfilePhoto} />
          <Typography className={classes.userEmail}>{userDetails.email}</Typography>

          <Box>
            <Link to='/logout' className={classes.logoutLink}>
              <Button variant="contained" color="primary" disableElevation className={classes.logoutButton}>
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </MuiMenu>
    </>
  );
}
Accounts.propTypes = {
  userDetails: PropTypes.shape({
    email: PropTypes.string,
    photoURL: PropTypes.string,
  }),
};
Accounts.defaultProps = {
  userDetails: {},
};
