import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useCallback } from 'react';
import { Refresh } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import { FiltersCtx } from '../../wrappers/Contexts/states/FiltersCtx';
import {
  formatDate,
  formatTime,
  subTime,
} from '../../../tools/functions/timeFormatting';
import conditionalCSS from './styles/FilterContainer';
import 'react-datepicker/dist/react-datepicker.css';
import { genericTimeFormat, selectableTimeRanges, sensors  } from '../../../tools/constants';
import { GraphDataCtx } from '../../wrappers/Contexts/states/GraphDataCtx';


export default function FilterContainer() {
  const {
    selectedFilter,
    setSelectedFilter,
    changeSelectedDate,
    selectedFromTime,
    changeSelectedFromTime,
    selectedToTime,
    changeSelectedToTime,
    selectedTimeRange,
    selectTimeRange,
  } = useContext(FiltersCtx);
  
  const {targets, fields} = useContext(GraphDataCtx);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = conditionalCSS({smDown, xsDown})();

  const refreshGraphData = useCallback(() => {
    if (!selectedTimeRange) return;
    const currentTime = formatTime(undefined);
    const subtractedTime = subTime(
      new Date(currentTime).toISOString(),
      `${selectedTimeRange} minutes`,
    );
    changeSelectedFromTime(subtractedTime);
    changeSelectedToTime(currentTime);
    changeSelectedDate(formatDate(currentTime).replace(/-/g, ' '));
  }, [
    changeSelectedFromTime,
    changeSelectedToTime,
    selectedTimeRange,
    changeSelectedDate,
  ]);

  useEffect(() => {
    refreshGraphData();
  }, [selectedTimeRange, refreshGraphData]);

  return (
    <Paper className={classes.filterPaper}>
      <Box className={classes.selectBoxes}>
        {targets && Object.keys(targets).length ? <FormControl variant="outlined">
          <InputLabel id="sensor-label">Sensor</InputLabel>
          <Select
            labelId="sensor-label"
            label="Sensor"
            variant="outlined"
            value={selectedFilter}
            className={classes.selectBox}
            onChange={(event) => {
              setSelectedFilter(event.target.value);
            }}
          >
            {targets && targets.map((target, index) => (
              <MenuItem key={`${index * 12}_sensorTargets`} value={target}>{(fields && fields[index]) ? fields[index] : (sensors[target] && sensors[target])}</MenuItem>
            ))}
          </Select>
        </FormControl> : null}
        <FormControl variant="outlined">
          <InputLabel id="time-range-id">Time Range</InputLabel>
          <Select
            labelId="time-range-id"
            label="Time Range"
            variant="outlined"
            value={selectedTimeRange}
            className={classes.selectBox}
            onChange={(event) => {
              selectTimeRange(event.target.value);
            }}
          >
            {selectableTimeRanges.map((timeRange) => (
              <MenuItem value={timeRange.value} key={timeRange.label}>
                {timeRange.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {!selectedTimeRange ? (
        <>
          {smDown ? <Divider /> : null}
          <Box className={classes.timeRangeContainer}>
            <Box className={classes.timeSelector}>
              <Box>
                <DateTimePicker
                  format={genericTimeFormat}
                  maxDate={new Date()}
                  value={selectedFromTime}
                  inputVariant="standard"
                  onChange={(date) => {
                    changeSelectedFromTime(formatTime(date));
                  }}
                  label="From"
                  className={classes.inputPickers}
                  inputProps={{
                    className: classes.inputPickerInput,
                  }}
                />
              </Box>
              <Box>
                <DateTimePicker
                  format={genericTimeFormat}
                  maxDate={new Date()}
                  inputVariant="standard"
                  value={selectedToTime}
                  onChange={(date) => {
                    changeSelectedToTime(formatTime(date));
                  }}
                  label="To"
                  className={classes.inputPickers}
                  inputProps={{
                    className: classes.inputPickerInput,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
      {selectedTimeRange ? (
        <IconButton color="secondary" onClick={refreshGraphData}>
          <Refresh />
        </IconButton>
      ) : null}
    </Paper>
  );
}
