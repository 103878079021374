const makeEndpoint = (request, localVariables) => {
  let url = request.target;
  const { variables } = request;

  Object.keys(variables).forEach((variable) => {
    const varName = variables[variable].name;
    const varType = variables[variable].type;
    if (varType === 'local') {
      const replacingVariable = localVariables[varName];
      url = String(url).replace(`[${varName}]`, replacingVariable);
    }
  });
  return url;
};
export default makeEndpoint;
