import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { Box, Drawer, List, ListItem, ListItemIcon, Tooltip, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Apps, GetApp, AssignmentTurnedIn, Explore } from '@material-ui/icons';
import conditionalCSS from './styles';

function MainSubNavigation({ children, expanded, show }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(expanded, xs)();
  const location = useLocation();

  if (!show) return null;
  return (
    <Box>
      <Drawer variant="permanent" PaperProps={{ className: classes.drawerPaper }} className={classes.mainDrawer}>
        <List>
          <ListItem className={classes.listItem}>
            <Link to="/devices" className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Devices" placement="right">
                  <Apps className={location.pathname === "/devices" ? classes.activeIcon : undefined} />
                </Tooltip>
              </ListItemIcon>
              <Typography className={[classes.listItemText, location.pathname === "/devices" ? classes.activeText : undefined].join(' ')}>
                Devices
              </Typography>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/download" className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Download" placement="right">
                  <GetApp className={location.pathname === "/download" ? classes.activeIcon : undefined} />
                </Tooltip>
              </ListItemIcon>
              <Typography className={[classes.listItemText, location.pathname === "/download" ? classes.activeText : undefined].join(' ')}>
                Download
              </Typography>
            </Link>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Link to="/try" className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="Try" placement="right">
                  <AssignmentTurnedIn className={location.pathname === "/try" ? classes.activeIcon : undefined} />
                </Tooltip>
              </ListItemIcon>
              <Typography className={[classes.listItemText, location.pathname === "/try" ? classes.activeText : undefined].join(' ')}>
                Try
              </Typography>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/live" className={classes.listAnchor}>
              <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title="View Live Map" placement="right">
                  <Explore className={location.pathname === "/live" ? classes.activeIcon : undefined} />
                </Tooltip>
              </ListItemIcon>
              <Typography className={[classes.listItemText, location.pathname === "/live" ? classes.activeText : undefined].join(' ')}>
                Live Map
              </Typography>
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <Box className={classes.siteContentContainer}>
        {children}
      </Box>
    </Box>
  )
}

MainSubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  show: PropTypes.bool.isRequired,
}
MainSubNavigation.defaultProps = {
  expanded: false,
}
export default MainSubNavigation
