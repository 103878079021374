import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const GraphDataCtx = createContext();

function GraphData({ children }) {
  const [graphData, setGraphData] = useState();
  const [targets, setTargets] = useState(undefined);
  const [fields, setFields] = useState(undefined);

  const value = { graphData, setGraphData, targets, setTargets, fields, setFields};

  return (
    <GraphDataCtx.Provider value={value}>{children}</GraphDataCtx.Provider>
  );
}

GraphData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GraphData;
