import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    levelMeterContainerBig: {
      width: xs ? 200 :300,
      position: 'relative',
      height: xs ? 200 : 300,
    },
    circularProgressBig: {
      width:  '100% !important',
      color: theme.palette.primary.main,
      height: '100% !important'
    },
    meterValueBig: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 'bold',
      fontSize: 40,
      userSelect: 'none'
    },
    levelMeterContainer: {
      width: 100,
      position: 'relative',
      height: 100,
    },
    circularProgress: {
      width:  '100% !important',
      color: theme.palette.primary.main,
      height: '100% !important'
    },
    meterValue: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 'bold'
    }
  }));
  return useStyles;
};

export default conditionalCSS;
