import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => {
    const defaultButtonStyles = {
      background: theme.palette.background.default,
      zIndex: 0,
      boxShadow: theme.shadows[1],
      position: 'absolute',
      '&:hover' : {
        background: theme.palette.background.default,
      }
    }
    const defaultLevelColorIndicatorStyles = {
      width: 20,
      height: 20,
      marginRight: 5,
    }
    return ({
      button: {
        ...defaultButtonStyles,
        top: theme.spacing(2),
        right: theme.spacing(2),
      },
      zoomBox: {
        ...defaultButtonStyles,
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      locationButton: {
        ...defaultButtonStyles,
        bottom: theme.spacing(8),
        right: theme.spacing(2),
      },
      levelMeterPaper: {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translate(calc(100% - 35px), -50%)',
        padding: 10,
        transition: 'transform 0.2s ease-in',
        '&:hover' : {
          transform: 'translate(0%, -50%)'
        }
      },
      levelBox: {
        display: 'flex'
      },
      levelText: {
        fontSize: 14,
      },
      good: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(110, 231, 119)',
      },
      moderate: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(254, 235, 60)',
      },
      poor: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(244, 94, 37)'
      },
      unhealthy: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(255, 0, 0)'
      },
      veryUnhealthy: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(175, 89, 190)'
      },
      hazardous: {
        ...defaultLevelColorIndicatorStyles,
        background: 'rgb(98, 56, 42)'
      },
      sensorName: {
        ...defaultButtonStyles,
        bottom: theme.spacing(2),
        right: theme.spacing(20),
        cursor: 'default',
        background: theme.palette.third.main,
        color: theme.palette.third.contrastText,
        fontWeight: 'bold',
        '&:hover' : {
          background: theme.palette.third.main,
        }
      },
      underDevelopmentPaper : {
        ...defaultButtonStyles,
        display: 'flex',
        bottom: theme.spacing(2),
        left: theme.spacing(5),
        padding: 10,
        alignItems:'center'
      },
      warningIconBox: {
        padding: 10,
        marginRight: 10,
      },
      warningIcon: {
        color: theme.palette.error.dark
      }
    })

  });
  return useStyles;
};

export default conditionalCSS;
