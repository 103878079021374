import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const MapStateContext = createContext();

const MapStateCtx = ({children}) => {
  const [mapZoom, setMapZoom] = useState(3);
  const [map, setMap] = useState();
  const [currentCoords, setCurrentCoords] = useState()


  const values = {
    zoom: mapZoom,
    setZoom: setMapZoom,
    map,
    setMap,
    currentCoords,
    setCurrentCoords
  }

  return (
    <MapStateContext.Provider value={values}>
      {children}
    </MapStateContext.Provider>
  )
}
MapStateCtx.propTypes = {
  children: PropTypes.node.isRequired,
}
export default MapStateCtx
