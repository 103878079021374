import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    container1: {
      minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
      background: theme.palette.background.default,
    },
    loadingOverlayBox: {
      position: 'fixed',
      zIndex: 99,
      width: '100%',
      height: '100vh',
    },
  }));
  return useStyles;
};

export default conditionalCSS;
