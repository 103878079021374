import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      minHeight: `100%`,
      background: theme.palette.background.default,
      width: '100%',
      padding: 20,
    },
    optionsCard: {
      padding: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: xs ? 'flex-start' : 'center',
      flexWrap: 'wrap',
      flexDirection: xs ? 'column' : undefined,
    },
    chooseDeviceText: {
      fontWeight: 'bold',
    },
    chooseAPIEndpoint: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      flexWrap: 'wrap',
    },
    rightArea: {
      display: 'flex',
      gap: 20,
      flexWrap: 'wrap',
      alignItems: xs ? 'flex-end' : undefined
    },
    credentialsBox: {
      display: 'flex',
      gap: xs ? 10 : 20,
      flexDirection: xs ? 'column' : undefined,
      alignItems: xs ? 'flex-start' : 'center',
    },
    credentialsItemBox: {
      display: 'flex',
      alignItems: 'center',
      marginTop: xs ? 10 : undefined,
      flexWrap: 'wrap'
    },
    credentialsItemTextTitle: {
      marginRight: 10,
      fontWeight: 'bold',
      fontSize: xs ? 14 : undefined
    },
    credentialsItemText: {
      fontSize: xs ? 14 : undefined
    },
    makeRequestButton: {
      minWidth: 'unset',
      width: 40,
      height: 40,
      borderRadius: '50%',
    },
    responseCard: {
      padding: 20,
      marginTop: 20,
    },
    requiredVariableInputCard: {
      margin: '10px 0px',
      padding: 20,
    },
    menuItemText: {
      width: '100%',
      height: '100%'
    },
  }));
  return useStyles;
};

export default conditionalCSS;
