import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    centerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: `calc(100vh - ${theme.spacing(8 * 2)}px)`,
      flexDirection: 'column',
      background: theme.palette.background.default,
      paddingLeft: 48,
    },
    text: {
      fontSize: xs && 32,
    }
  }));
  return useStyles;
};
export default conditionalCSS;
