import { Box, Button, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Fullscreen, ViewCompact, ViewList } from '@material-ui/icons'
import { devicesSnapshotListener } from '../../../tools/functions/firestore';
import FocusedView from './FocusedView';
import conditionalCSS from './styles/visualize';
import SimultaneousView from './SimultaneousView';

function Visualize() {
  const { deviceId } = useParams();
  const [liveData, updateLiveData] = useState();
  const classes = conditionalCSS()();
  const viewContainerRef = useRef();
  const [view, setView] = useState('compact');
  const [showFullscreen, updateShowFullScreen] = useState(true);

  useEffect(() => {
    devicesSnapshotListener(deviceId, (data) => {
      // deleting unnecessary fields
      delete data.rtc;
      // updating data
      updateLiveData(data);
    })
  }, [deviceId]);

  useEffect(() => {

    const fullscreen = () => {
      if (document.fullscreenElement !== null) {
        updateShowFullScreen(false);
      } else {
        updateShowFullScreen(true);
      }
    }
    document.addEventListener("fullscreenchange", fullscreen);
    return () => {
      document.removeEventListener("fullscreenchange", fullscreen);
    }
  }, [])



  return (
    <>
      <Box ref={viewContainerRef} className={classes.viewContainer}>

        {showFullscreen ? <Button className={classes.fullScreenButton} onClick={() => {
          viewContainerRef.current.requestFullscreen();
        }}>
          <Fullscreen />
        </Button> : null}
        {view === "compact" ? <FocusedView data={liveData} /> : <SimultaneousView data={liveData} />}
        <Box className={classes.viewToggleBox}>
          <Tooltip title="Compact View">
            <Button className={classes.viewToggleButton} disableRipple onClick={() => {
              setView('compact')
            }}>
              <ViewCompact className={view === "compact" && classes.activeToggleButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Expanded View">
            <Button className={classes.viewToggleButton} disableRipple onClick={() => {
              setView('list');
            }}>
              <ViewList className={view === "list" && classes.activeToggleButton} />
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </>)
}

export default Visualize
