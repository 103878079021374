import { Box, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import LevelMeter from './LevelMeter'
import conditionalCSS from './styles/visualize';
import { GraphDataCtx } from '../../wrappers/Contexts/states/GraphDataCtx';
import { getDeviceTargetsAndFields } from '../../../tools/functions/firestore';
import { sensors } from '../../../tools/constants';
import { formatToUNIX, readableTimeFormat, subTime } from '../../../tools/functions/timeFormatting';

function SimultaneousView({data}) {  
  const classes = conditionalCSS()();
  const {targets, setTargets, fields, setFields} = useContext(GraphDataCtx);
  const {deviceId} = useParams();
  const [showingTime, setShowingTime] = useState();

  useEffect(() => {
    if(!targets) {
      getDeviceTargetsAndFields(deviceId).then((resultTargets, resultFields) => {
        setTargets(resultTargets);
        setFields(resultFields);
      })
    }
  }, [targets, deviceId, setTargets, setFields])

  useEffect(() => {
    let intervalTime = 0;
    const updateTime = setInterval(() => {
      intervalTime = 5000;
      if(data && data.createdAt < formatToUNIX(subTime(new Date(), '1 minute'))){
        setShowingTime(readableTimeFormat(data.createdAt));
      } else {
        setShowingTime(undefined)
      }
    }, intervalTime);
    return () => {
      clearInterval(updateTime)
    }
  }, [data])

  return (
    <Box className={classes.simultaneousViewContainer}>
      <Box className={classes.simultaneousViewBox}>
        {targets && targets.map((target, index) => {
          if(data && data[target]){
            return (<Box className={classes.simultaneousViewSensorBox}>
                        <LevelMeter value={data[target]} />
                        <Typography>{(fields && fields[index]) ? fields[index] : sensors[target]}</Typography>
                    </Box>)
          }
          return null;
        })}
      </Box>
      {showingTime ? 
        <Box className={classes.lastUpdatedAtBox}>
          <Typography className={classes.updatedAtText}>
            @ {showingTime}
          </Typography>
        </Box>
      : null}
    </Box>
  )
}

SimultaneousView.propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.number
  }),
}
SimultaneousView.defaultProps = {
  data: undefined
}

export default SimultaneousView
