import React, { useEffect, useContext, useRef, useCallback, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper, Tooltip, Typography, FormControl, useTheme, useMediaQuery } from '@material-ui/core';

import { Fullscreen } from '@material-ui/icons';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import conditionalCSS from './styles/Graph';
import { getGraphData } from '../../tools/functions/firestore';
import { FiltersCtx } from '../wrappers/Contexts/states/FiltersCtx';
import { formatTime } from '../../tools/functions/timeFormatting';
import { GraphDataCtx } from '../wrappers/Contexts/states/GraphDataCtx';
import { LoaderCtx } from '../wrappers/Loader';

const options = {
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: 'MM/dd/yyyy - hh:mm:ss a',
      },
      displayFormats: {
        unit: 'month',
      },
      ticks: {
        source: 'labels',
        autoSkip: true,
        minRotation: 90,
        maxTicksLimit: 10
      },
    },
    y: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
  },
  animation: false,
  tooltips: {
    enabled: true,
  },
};

export default function Graph() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();
  const { deviceId: mac } = useParams();
  const { selectedFilter, selectedFromTime, selectedToTime, selectedDate } =
    useContext(FiltersCtx);
  const { setGraphData, graphData, setTargets, setFields } = useContext(GraphDataCtx);
  const paperRef = useRef();
  const { changeLoaderState } = useContext(LoaderCtx);
  const [startChartFromZero, setStartChartFromZero] = useState(false);
  const [timeBasedGap, setTimeBasedGap] = useState(true);


  const fetchGraphData = useCallback(() => {
    if (
      mac !== '' &&
      selectedFromTime !== '' &&
      selectedToTime !== '' &&
      selectedDate !== ''
    ) {
      changeLoaderState(true);
      getGraphData({
        mac,
        localFromTime: selectedFromTime,
        localToTime: selectedToTime,
      })
        .then((result) => {
          changeLoaderState(false);
          setGraphData(result.data);
          setTargets(result.targets);
          setFields(result.fields)
        })
        .catch(() => {
          changeLoaderState(false);
        });
    }
  }, [
    mac,
    selectedFromTime,
    selectedToTime,
    setTargets,
    setFields,
    selectedDate,
    setGraphData,
    changeLoaderState,
  ]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  useEffect(
    () => () => {
      setGraphData();
    },
    [setGraphData],
  );

  useEffect(
    () => () => {
      changeLoaderState(false);
    },
    [changeLoaderState],
  );

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
    paperRef.current.requestFullscreen();
  };
  if (!graphData) return null;
  return (
    <Paper
      className={
        !graphData.length ? classes.graphPaperNoData : classes.graphPaper
      }
      ref={paperRef}
    >
      {graphData.length ? (
        <>
          <Box className={classes.graphContainer}>

            <Box className={classes.graphOptionsBox}>
              <FormGroup className={classes.checkboxesFormGroup}>
                <FormControl>
                  <FormControlLabel
                    label="Time Gap"
                    control={
                      <Checkbox
                        checked={timeBasedGap}
                        color="primary"
                        onClick={(event) => {
                          setTimeBasedGap(event.target.checked);
                        }}
                      />
                    }
                    classes={{
                      label: classes.checkboxLabel
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    label="Start at 0"
                    control={
                      <Checkbox
                        checked={startChartFromZero}
                        color="primary"
                        onClick={(event) => {
                          setStartChartFromZero(event.target.checked);
                        }}
                      />
                    }
                    classes={{
                      label: classes.checkboxLabel
                    }}
                  />
                </FormControl>
              </FormGroup>

              <Tooltip title="Enter Fullscreen">
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={classes.fullScreenBtn}
                  onClick={toggleFullScreen}
                >
                  <Fullscreen />
                </Button>
              </Tooltip>
            </Box>
            <Line
              data={{
                labels: graphData.map((eachData) =>
                  formatTime(eachData.createdAt),
                ),
                datasets: [
                  {
                    spanGaps: false,
                    label: 'Sensor',
                    data: graphData.map((eachData) => ({
                      y: eachData[selectedFilter],
                      x: formatTime(eachData.createdAt),
                    })),
                    fill: false,
                    pointRadius: 2,
                    backgroundColor: '#e84393',
                    borderColor: '#e84393',
                  },
                ],
              }}
              options={{
                ...options,
                scales: {
                  x: {
                    type: timeBasedGap ? 'time' : 'timeseries',
                    ticks: {
                      maxTicksLimit: timeBasedGap && 5,
                      source: 'labels',
                      minRotation: 90,
                    },
                  },
                  y: {
                    min: startChartFromZero ? 0 : undefined,
                    ticks: {
                      beginAtZero: startChartFromZero,
                    }
                  }
                }
              }
              }
            />
          </Box>
        </>
      ) : (
        <Typography>No Data Available.</Typography>
      )}
    </Paper>
  );
}
