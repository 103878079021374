import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import DevicesDataError from '../../components/Devices/DevicesDataError';
import Navigation from '../../components/Navigation';
import { DevicesListCtx } from '../../components/wrappers/Contexts/states/DevicesCtx';
import conditionalCSS from './styles';
import { LoaderCtx } from '../../components/wrappers/Loader';
import Dashboard from '../../components/SingleDevice/Dashboard';
import Visualize from '../../components/SingleDevice/Visualizer';

export default function SingleDevice() {
  const { deviceId, route } = useParams();
  const [singleDeviceInfo, setSingleDeviceInfo] = useState();
  const { getSingleDevice } = useContext(DevicesListCtx);
  const classes = conditionalCSS()();
  const { state } = useContext(LoaderCtx);
  useEffect(() => {
    (async () => {
      const device = await getSingleDevice(deviceId);
      if (device) {
        setSingleDeviceInfo(device);
      } else {
        setSingleDeviceInfo(null);
      }
    })();
  }, [deviceId, getSingleDevice]);

  return (
    <>
      <Navigation>
        {!singleDeviceInfo ? (
          <DevicesDataError
            text={
              singleDeviceInfo === undefined
                ? 'Loading...'
                : 'No Device Available.'
            }
          />
        ) : (
          <Box className={classes.container1}>
            <Box className={state ? classes.loadingOverlayBox : ''} />
            {route === "dashboard" ? <>
              <Dashboard singleDeviceInfo={singleDeviceInfo} />
            </> : <Visualize />}
          </Box>
        )}
      </Navigation>
    </>
  );
}