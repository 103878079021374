import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#249BCF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F15179',
      contrastText: '#fff',
    },
    third: {
      main: "#9a0036",
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'],
  },
});

export default theme;
