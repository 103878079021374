// ** This source code has been taken from stackoverflow ** [https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript]

function fallbackCopyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.display = 'none';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        resolve();
      } else {
        reject();
      }
    } catch {
      reject();
    }
    document.body.removeChild(textArea);
  });
}
function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text).then(resolve).catch(reject);
    }
    navigator.clipboard.writeText(text).then(
      () => {
        resolve();
      },
      () => {
        reject();
      },
    );
  });
}
export default copyTextToClipboard;
