import { Box, Divider, MenuItem, Paper, TextField, Button, CircularProgress } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { DateTimePicker } from '@material-ui/pickers';
import { GetApp } from '@material-ui/icons';
import Navigation from '../../components/Navigation'
import { AuthContext } from '../../components/wrappers/Authentication';
import { DevicesListCtx } from '../../components/wrappers/Contexts/states/DevicesCtx';
import { genericTimeFormat, selectableTimeRanges } from '../../tools/constants';
import { getAllDevices, } from '../../tools/functions/firestore';
import conditionalCSS from './styles';
import { formatTime, subTime } from '../../tools/functions/timeFormatting';
import { downloadGraphFirestore } from '../../tools/functions/downloadGraph';

function Download() {
  const history = useHistory();
  const { details } = useContext(AuthContext);
  const { devicesList, setDevicesList, setDevicesLocationList } = useContext(DevicesListCtx);
  const [selectedDevice, setSelectedDevice] = useState((devicesList && devicesList.length) ? devicesList[0].deviceID : '');
  const [timeSelector, updateTimeSelector] = useState('');
  const [showCustomTimeSelector, setShowCustomTimeSelector] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedFromTime, setSelectedFromTime] = useState(subTime(new Date(), '60 minutes'));
  const [selectedToTime, setSelectedToTime] = useState(formatTime(new Date()));
  const classes = conditionalCSS()();

  useEffect(() => {
    if (!devicesList && details) {
      getAllDevices(details).then((devices) => {
        if (devices && devices.length) {
          setDevicesList(devices);
        } else if (devices && !devices.length) {
          setDevicesList([]);
        }
      })
    }
  }, [devicesList, details, setDevicesList, setDevicesLocationList])


  useEffect(() => {
    if (timeSelector === 0) {
      setShowCustomTimeSelector(true);
      setSelectedTimeRange({ from: selectedFromTime, to: selectedToTime })
    } else if (timeSelector) {
      setShowCustomTimeSelector(false);
      const from = subTime(new Date().toISOString(), `${timeSelector} minutes`)
      const to = formatTime(new Date())
      setSelectedTimeRange({ from, to })
    } else {
      setShowCustomTimeSelector(false);

    }
  }, [timeSelector, selectedFromTime, selectedToTime]);

  useEffect(() => {
    if (selectedDevice.length && selectedTimeRange) {
      setShowDownloadButton(true);
    } else {
      setShowDownloadButton(false);
    }
  }, [selectedDevice, selectedTimeRange])

  useEffect(() => {
    const { search } = history.location;
    if (search !== '') {
      const { id } = qs.parse(search);
      if (id !== null && id !== "" && devicesList && devicesList.length) {
        setSelectedDevice(id);
      }
    }
  }, [history.location, devicesList]);

  // function

  const downloadData = async () => {
    setLoading(true);

    downloadGraphFirestore({
      from: selectedFromTime,
      to: selectedToTime,
      mac: selectedDevice,
      token: details.token,
    });

    setLoading(false);
  }

  return (
    <>
      <Navigation>
        <Box className={classes.downloadContainer}>
          <Paper className={classes.paperBox}>
            <Box className={classes.inputFieldsAndDownloadButton}>
              <Box className={classes.inputFieldsBox}>
                <TextField select className={classes.formControl} label="MAC ID" value={selectedDevice} variant="outlined" onChange={(event) => {
                  setSelectedDevice(event.target.value);
                }}>
                  {devicesList && devicesList.length ? devicesList.map((device) => (
                    <MenuItem value={device.deviceID} key={device.deviceID}>{device.deviceID}</MenuItem>
                  )) : <MenuItem value="" disabled>{devicesList && !devicesList.length ? "No Device Found." : "Loading..."}</MenuItem>}
                </TextField>
                <TextField select className={classes.formControl} variant="outlined" label="Time Range" value={timeSelector} onChange={(event) => updateTimeSelector(event.target.value)}>
                  {selectableTimeRanges.map((range) => (
                    <MenuItem value={range.value} key={range.value}>{range.label}</MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                {!loading ? <Button color="primary" variant="contained" disabled={!showDownloadButton} disableElevation className={classes.button} onClick={downloadData}><GetApp /></Button>
                  : <Box className={classes.loadingSpinner}><CircularProgress /></Box>}
              </Box>
            </Box>
            <Box>
              {showCustomTimeSelector ? <>
                <Divider className={classes.divider} />

                <DateTimePicker
                  format={genericTimeFormat}
                  inputVariant="standard"
                  label="From"
                  value={selectedFromTime}
                  className={classes.inputPicker}
                  onChange={(time) => {
                    setSelectedFromTime(time)
                  }}
                />

                <DateTimePicker
                  format={genericTimeFormat}

                  inputVariant="standard"
                  label="To"
                  value={selectedToTime}
                  className={classes.inputPicker}
                  onChange={(time) => {
                    setSelectedToTime(time)
                  }}
                />
              </> : null}
            </Box>
          </Paper>
        </Box>
      </Navigation>
    </>
  )
}


export default Download;