import { makeStyles } from '@material-ui/core';

const conditionalCSS = (homePage, xs) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      minWidth: 'unset',
    },
    title: {
      flexGrow: 1,
    },
    homePageLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      marginLeft: 20,
    },
    appBar: {
      background: homePage ? '#fff' : theme.palette.primary.main,
      zIndex: 100000
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
    },
    leftArea: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '40px',
      height: '40px',
      background: 'white',
      borderRadius: '50%',
      marginRight: 20,
    },
    anchorBtn: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    button: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.contrastText,
    },
    menuIcon: {
      color: theme.palette.common.white
    },
    contentContainer: {
      marginTop: xs ? theme.spacing(7) : theme.spacing(8)
    }
  }));
  return useStyles;
};
export default conditionalCSS;
