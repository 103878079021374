import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles(() => ({
    graphPaper: {
      margin: xs ? '10px' : '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      padding: '20px 10px',
    },
    fullScreenBtn: {
    },
    graphPaperNoData: {
      margin: '0px auto',
      width: '95%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    graphContainer: {
      width: '100%',
    },
    graphOptionsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 10,
    },
    checkboxesFormGroup: {
      gap: 20,
      flexDirection: 'row',
      flexWrap: xs ? 'nowrap' : 'wrap',
      alignItems: 'center'
    },
    checkboxLabel: {
      fontSize: xs && '14px',
    }
  }));
  return useStyles;
};
export default conditionalCSS;
