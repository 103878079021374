import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SensorContext = createContext();

function SensorCtx({ children }) {
  const [sensorsList, setSensorsList] = useState();

  const addDevice = (newDevice) => {
    setSensorsList((previousSensorsList) => {
      if (Array.isArray(previousSensorsList)) {
        previousSensorsList.push(newDevice);
      } else {
        return [newDevice];
      }
      return previousSensorsList;
    });
  };
  const value = {
    sensorsList,
    addDevice,
  };

  return (
    <SensorContext.Provider value={value}>{children}</SensorContext.Provider>
  );
}

SensorCtx.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SensorCtx;
