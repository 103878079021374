import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    mapContainer: {
      position: 'relative',
      overflow: 'hidden',
      '&:fullscreen > $mapBox' : {
        height: '100vh'
      }
    },
    mapBox: {
      width: '100%',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    map : { 
      width: '100%',
      height: '100%'
    }
  }));
  return useStyles;
};

export default conditionalCSS;
