import { useMediaQuery, makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => {
    const isSmallDevice = useMediaQuery('(max-width: 800px)');
    return {
      container: {
        padding: xs ? '30px 10px' : 30,
        minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
        background: theme.palette.background.default,
      },
      cardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
        width: xs ? '100%' : '90%',
        marginTop: 20,
        justifyContent: isSmallDevice ? 'center' : undefined,
      },
      card: {
        width: 350,
        color: 'black',
        height: 'max-content',
      },
      cardAnchor: {
        textDecoration: 'none',
      },
      linkContainer: {
        paddingBottom: 10,
      },
      deviceName: {
        fontWeight: 800,
        color: 'black',
      },
      deviceMacID: {
        margin: '5px 0px',
        fontSize: '14px',
      },
      locationBox: {
        display: 'flex',
        gap: 10,
      },
      deviceLocationText: {
        fontSize: 14,
      },
      coordinatesBox: {
        display: 'flex',
        justifyContent: xs ? 'flex-start' : 'space-evenly',
        flexDirection: xs && 'column',
        marginBottom: 10,
      },
      smallText: {
        fontSize: 14,
        margin: '0px 2px',
      },
      coordinateButton: {
        width: 'max-content',
        minWidth: 150,
        marginBottom: xs && 5,
        justifyContent: xs ? 'flex-start' : 'center',
      },
      coordinatesSingleBox: {
        display: 'flex',
        gap: 1,
        textTransform: 'capitalize',
      },
      cardTitleDescriptionAndHistory: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      deviceItemBox: {
        display: 'flex',
        marginBottom: 5,
        alignItems: 'center',
      },
      deviceItemIcon: {
        color: '#000',
        marginRight: 10,
      },
      cardButtonBox: {
        display: 'flex',
        justifyContent:'space-between',
      },
      locationAndDownloadBox: {
        display: 'flex',
        gap: 10,
      },
      cardButton: {
        width: 40,
        minWidth: 'unset',
        height: 40,
        borderRadius: '50%',
      },
      
    };
  });
  return useStyles;
};
export default conditionalCSS;
