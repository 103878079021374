import { Box } from '@material-ui/core';
import React from 'react';
import Navigation from '../../components/Navigation';
import AppDownloadSection from './AppDownloadSection';
import ConnectDevice from './ConnectDevice';
import EzioStatWebPortalSection from './EzioStatWebPortalSection';
import LandingSection from './LandingSection';

function Home() {
  return (
    <Box>
      <Navigation homePage>
        <LandingSection />
        <ConnectDevice />
        <AppDownloadSection />
        <EzioStatWebPortalSection />
      </Navigation>
    </Box>
  );
}

export default Home;
