import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { History, LocationOn } from '@material-ui/icons';
import conditionalCSS from './styles/Details';
import FilterContainer from './FilterContainer';

export default function Details({ data }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();

  return (
    <Box className={classes.detailsContainer}>
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.deviceDetailsAndHistory}>
            <Box>
              <Typography className={classes.deviceName}>
                {data.name}
              </Typography>
              <Typography className={classes.deviceMacID} color="textSecondary">
                {data.macID}
              </Typography>
            </Box>
            <Link to={`/history/${data.macID}`}>
              <Button disableElevation>
                <History color="action" />
              </Button>
            </Link>
          </Box>
          {data.loc ? (
            <Box className={classes.locationBox}>
              <LocationOn fontSize="large" />
              <Typography className={classes.deviceLocationText}>
                {data.loc}
              </Typography>
            </Box>
          ) : null}
        </CardContent>
      </Card>
      <FilterContainer />
    </Box>
  );
}
Details.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    macID: PropTypes.string,
    loc: PropTypes.string,
  }).isRequired,
};
