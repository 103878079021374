import React from 'react';
import PropTypes from 'prop-types';
import SensorCtx from './states/SensorCtx';
import DevicesCtx from './states/DevicesCtx';
import Filters from './states/FiltersCtx';
import GraphData from './states/GraphDataCtx';
import MapStateCtx from './states/MapStateCtx';

const Contexts = ({ children }) => (
  <>
    <Filters>
      <DevicesCtx>
        <GraphData>
          <MapStateCtx>
            <SensorCtx>{children}</SensorCtx>
          </MapStateCtx>
        </GraphData>
      </DevicesCtx>
    </Filters>
  </>
);

Contexts.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Contexts;
