import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: xs ? '10px' : '50px',
      background: theme.palette.background.default,
      minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    heading: {
      fontSize: xs && 32,
      marginTop: 30,
    },
    card: {
      width: '90%',
      maxWidth: 350,
      height: 'max-content',
    },
    cardItemBox: {
      display: 'flex',
      margin: '10px 0px',
    },
    itemIcon: {
      marginTop: 5,
      marginRight: 10,
    },
    tableContainer: {
      marginTop: 20,
    },
    table: {
      minWidth: 850,
    },
    cardsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 30,
      marginTop: 20,
    },
    locationName: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 22,
      marginBottom: 10,
    },
    timeText: {
      marginTop: 5,
    },
    emailText: {
      marginTop: 7,
      fontSize: 14,
    },
    mapUrl: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }));
  return useStyles;
};

export default conditionalCSS;
