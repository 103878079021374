import { makeStyles } from '@material-ui/core';

const conditionalCSS = (expanded, xs) => {
  const useStyles = makeStyles((theme) => {
    
    const drawerWidth = expanded ? theme.spacing(20) :theme.spacing(6);

    const drawer = {
      width: drawerWidth,
      height: '100%',
      marginTop: xs ? theme.spacing(7) : theme.spacing(8),
      transform: xs ? (`${expanded ? 'translateX(0%)' : 'translateX(-100%)'} `) : 'translateX(0%)',
      transition: 'transform 0.2s, width 0.2s',
    }
    
    return ({
    drawer,
    drawerPaper: drawer,
    mainDrawer: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100,
    },
    siteContentContainer: {
      width: '100%',
      paddingLeft: xs ? 0 : drawerWidth,
      transition: 'padding-left 0.2s ease-in'
    },
    activeIcon: {
      color: theme.palette.primary.main
    },
    activeText: {
      color: `${theme.palette.primary.main} !important`
    },
    listItem: {
      display: 'flex',
      justifyContent: !expanded ? 'center' : 'flex-start',
      padding:expanded ? '10px 15px' : '10px 0px'
    },
    listAnchor : {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      padding: '5px 0px'
    },
    listItemIcon: {
      width: 24,
      height: 24,
      minWidth: 'unset'
    },
    iconActive: {
      fill: theme.palette.primary.main
    },
    listItemText: {
      color: theme.palette.grey[700],
      marginLeft: 10,
      fontWeight: 'bold',
      display: !expanded ? 'none' : 'block',
      whiteSpace: 'nowrap',
    },
  })});
  return useStyles;
};

export default conditionalCSS;
