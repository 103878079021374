import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, TextField, Tooltip } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import conditionalCSS from './styles/EndpointURL';

function EndpointURL({ onCopy, url }) {
  const classes = conditionalCSS()();

  return (
    <>
      <Card className={classes.urlInputCard}>
        <TextField size="small" variant="outlined" disabled value={url} className={classes.urlInputField} />
        <Tooltip title="Copy Request Information" placement="top">
          <Button onClick={onCopy} color="secondary" variant="contained" disableElevation>
            <FileCopy />
          </Button>
        </Tooltip>
      </Card>
    </>
  );
}

EndpointURL.propTypes = {
  url: PropTypes.string.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default EndpointURL;
