import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {Button, Box, Paper, Typography} from '@material-ui/core';
import {Fullscreen, Add, Remove, MyLocation, /* Warning | ** for development message ** */} from '@material-ui/icons';
import conditionalCSS from './styles'
import { MapStateContext } from '../../wrappers/Contexts/states/MapStateCtx';
import getDataPointColorInformation from '../../../tools/functions/getDataPointColorInformation';

function Options({mapElement}) {
  const mapRef = useRef();
  const classes = conditionalCSS()();
  const { setZoom, map, zoom, setCurrentCoords } = useContext(MapStateContext);

  // functions

  const toggleFullscreen = () => {
    if(document.fullscreenElement === null){
      mapRef.current.requestFullscreen();
    }
    else {
      document.exitFullscreen();
    }
  }
    // zoom

    const zoomIn = () => {
      if(map){
        setZoom(map.getZoom() + 1);
      }
    };

    const zoomOut = () => {
      if(zoom !== 3) {
        setZoom(zoom - 1) 
      }
    };

    // move to current location
    const moveToCurrentLocation = () => {
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition((position) => {
          setCurrentCoords(position.coords)
        });
      }
    }
  // effects

  useEffect(() => {
    mapRef.current = mapElement;
  }, [mapElement])

  return (
    <>
      
      {/* full screen button */}
      <Button className={classes.button} onClick={toggleFullscreen} variant="contained">
        <Fullscreen />
      </Button>

      {/* move to current location */}

      <Button className={classes.locationButton} onClick={moveToCurrentLocation}>
        <MyLocation />
      </Button>

      {/* zoom buttons */}
      <Box className={classes.zoomBox}>
        <Button className={classes.zoomButton} onClick={zoomIn}>
          <Add />
        </Button>
        <Button className={classes.zoomButton} onClick={zoomOut}>
          <Remove />
        </Button>
      </Box>

      {/* level meter */}
      <Paper className={classes.levelMeterPaper}>

        <Box className={classes.levelBox}>
          <Box className={classes.good} />
          <Typography className={classes.levelText}>
            {getDataPointColorInformation(50).label} - {getDataPointColorInformation(50).max}
          </Typography>
        </Box>
        <Box className={classes.levelBox}>
          <Box className={classes.moderate} />
          <Typography className={classes.levelText}>
          {getDataPointColorInformation(100).label} - {getDataPointColorInformation(100).max}
          </Typography>
        </Box>

        <Box className={classes.levelBox}>
          <Box className={classes.poor} />
          <Typography className={classes.levelText}>
          {getDataPointColorInformation(150).label} - {getDataPointColorInformation(150).max}
          </Typography>
        </Box>
        <Box className={classes.levelBox}>
          <Box className={classes.unhealthy} />
          <Typography className={classes.levelText}>
          {getDataPointColorInformation(200).label} - {getDataPointColorInformation(200).max}
          </Typography>
        </Box>
        <Box className={classes.levelBox}>
          <Box className={classes.veryUnhealthy} />
          <Typography className={classes.levelText}>
          {getDataPointColorInformation(300).label} - {getDataPointColorInformation(300).max}
          </Typography>
        </Box>
        <Box className={classes.levelBox}>
          <Box className={classes.hazardous} />
          <Typography className={classes.levelText}>
          {getDataPointColorInformation(301).label} - {getDataPointColorInformation(301).max}
          </Typography>
        </Box>
      </Paper>


      {/* sensor name */}

      <Button className={classes.sensorName} disableRipple>
        PM 2.5
      </Button>

    {/* under development information */}

    {/* <Paper className={classes.underDevelopmentPaper}>
      <Box className={classes.warningIconBox}>
        <Warning className={classes.warningIcon}/>
      </Box>
      <Typography>
        This feature is under development. Please don&#39;t use.
      </Typography>
    </Paper> */}
    
    </>
  )
}
Options.propTypes = {
  mapElement: PropTypes.shape({}),
}
Options.defaultProps = {
  mapElement: undefined,
}
export default Options
