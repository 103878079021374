import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Radio, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Navigation from '../../components/Navigation';
import { getDeviceDefaultLocationId, getDeviceLocations, setDeviceDefaultLocationId } from '../../tools/functions/firestore';
import conditionalCSS from './styles';
import { formatTime } from '../../tools/functions/timeFormatting'
import { AuthContext } from '../../components/wrappers/Authentication';

function History() {
  const params = useParams();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { details } = useContext(AuthContext);
  const [locations, setLocations] = useState([]);
  const [defaultLocationId, setDefaultLocationId] = useState();
  const [localeDefaultLocationId, setLocaleDefaultLocationId] = useState();
  const [radioDisabled, setRadioDisabled] = useState();
  const classes = conditionalCSS(xs)();


  const getDefaultLocation = useCallback(async () => {
    const defaultLocation = await getDeviceDefaultLocationId({ macId: params.deviceId, userId: details.docId });
    setDefaultLocationId(defaultLocation)
  }, [params, details]);

  const setDefaultLocation = useCallback(async (documentId) => setDeviceDefaultLocationId(details.docId, params.deviceId, documentId), [params, details]);

  useEffect(() => {
    const fetchDeviceLocations = (async () => {
      let data = await getDeviceLocations(params.deviceId);
      if (data && data.length) {
        data = data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
      }
      setLocations(data);
    })();
    return () => {
      Promise.resolve(fetchDeviceLocations);
    };
  }, [params.deviceId]);

  useEffect(() => {

    getDefaultLocation();

  }, [getDefaultLocation]);

  useEffect(() => {
    if (!defaultLocationId) {
      const userLocations = locations.filter(location => location.email === details.email);
      if (userLocations.length) {
        setLocaleDefaultLocationId(userLocations[0].docId);
      }
    } else {
      setLocaleDefaultLocationId(undefined);
    }
  }, [defaultLocationId, details.email, locations])



  return (
    <>
      <Navigation>
        <Box className={classes.container}>
          <Typography variant="h4" className={classes.heading}>Location History</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Coordinates</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Default</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locations.map((location, index) => (
                  <TableRow key={`${index * 3}_${location.name}`}>
                    <TableCell component="th" scope="row">
                      {location.name}
                    </TableCell>
                    <TableCell>
                      <a href={`https://www.google.com/maps?q=${location.lat},${location.lng}&sll=${location.lat},${location.lng}`} target="_blank" rel="noopener noreferrer" className={classes.mapUrl}>
                        ({location.lat}, {location.lng})
                      </a>
                    </TableCell>
                    <TableCell>{formatTime(location.createdAt, 'dd-MM-yyyy hh:mm:ss a')}</TableCell>
                    <TableCell>{location.email}</TableCell>
                    <TableCell>{location.loc}</TableCell>
                    <TableCell>
                      <Radio value={location.docId.trim()} disabled={radioDisabled} checked={location.docId === localeDefaultLocationId || location.docId.trim() === defaultLocationId?.trim()} onChange={(event) => {
                        setRadioDisabled(true);

                        setDefaultLocation(event.target.value).then(() => {
                          getDefaultLocation();
                          setRadioDisabled(false);
                        }).catch(() => {
                          setRadioDisabled(false);
                        });

                      }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Navigation>
    </>
  );
}

export default History;
