import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    container: xs ? ({
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }) : ({
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      padding: '2% 5%',
      display: 'flex',
      justifyContent: 'space-between',
    }),
    leftArea: {
      marginTop: xs ? 50 : 100,
      padding: 20,
    },
    eziostatHeading: xs && ({
      fontSize: 32,
    }),
    subHeading: {
      margin: '10px 5px',
      fontSize: xs && 22,
    },
    CTALogin: {
      marginLeft: 5,
      textDecoration: 'none',
    },
    appScreenImage: {
      height: xs ? 250 : 500,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
