import React from 'react';
import PropTypes from 'prop-types';
import Graph from '../../Graph';
import Details from './Details';
import Download from './Download';

function Dashboard({ singleDeviceInfo }) {
  return (
    <>
      <Details data={singleDeviceInfo} />
      <Graph />
      <Download />
    </>
  )
}

Dashboard.propTypes = {
  singleDeviceInfo: PropTypes.shape({}),
}
Dashboard.defaultProps = {
  singleDeviceInfo: undefined,
}
export default Dashboard
