import qs from 'query-string';
import {Parser} from 'json2csv';
import { formatDate, formatTime, formatToUNIX } from "./timeFormatting";

const downloadGraph = async ({graphData, getSingleDevice, mac, targets}) => {

  const { name } = await getSingleDevice(mac);


  const downloadingData = graphData.map((eachData) => {

    const date = formatDate(eachData.createdAt);
    const time = formatTime(eachData.createdAt).split('T')[1];
    const localtime = `${date}T${time}`;
    
    const data = {};
    targets.forEach((target) => {
      if(eachData[target]){
        data[target] = eachData[target];
      } else {
        data[target] = 'null'
      }
    })
    data.createdAt = eachData.createdAt;
    data.localtime = localtime;
    const {lat, long} = eachData;
    if(lat !== undefined && long !== undefined) {
      data.lat = lat;
      data.long = long;
    }
    return data;
  });
  const parser = new Parser();
  const csv = parser.parse(downloadingData);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  // Create a link to download it
  const aTag = document.createElement('a');
  aTag.href = url;
  aTag.setAttribute('download', `${name}__${mac.replace(/:/g, '_')}.csv`);
  aTag.click();
};

export const downloadGraphFirestore = async ({mac, from, to, token}) => {
  from = formatToUNIX(from);
  to = formatToUNIX(to);
  
  // get timezone offset in string e.g. 5:30

  const currentTime = new Date().toString();

  const offset = currentTime.split('GMT')[1].split(' ')[0]

  const timeZoneOffset = `${offset.substring(0, offset.length - 2)}:${ offset.substring(offset.length - 2, offset.length)}`;
  const params = qs.stringify({from, to, token, mac, tz: timeZoneOffset}, {})
  window.open(`${process.env.REACT_APP_FIREBASE_FUNCTIONS}/download?${params}`)
};


export default downloadGraph;