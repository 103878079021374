import React, { useContext, useState } from 'react';
import { Box, Button, Card, CardContent, IconButton, Snackbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { Close, LocationOn, Dns, Fingerprint, ArrowForward, GetApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import conditionalCSS from './styles/ListDevices';
import { DevicesListCtx } from '../wrappers/Contexts/states/DevicesCtx';
import copyTextToClipboard from '../../tools/functions/copyToClipboard';

export default function ListDevices() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { devicesList } = useContext(DevicesListCtx);
  const classes = conditionalCSS(xs)();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const copyToClipboard = (text) => {
    copyTextToClipboard(text).then(() => {
      setSnackbarOpen('Copied to clipboard!');
    }).catch(() => {
      setSnackbarOpen('Unable to copy!')
    });
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (!devicesList) return null;

  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h4">My Devices</Typography>
        <Box className={classes.cardsContainer}>
          {devicesList.map((deviceInfo, index) => {
            const { data } = deviceInfo;
            return (
              <Card className={classes.card} key={`${index + 2}_grid-item`}>
                <CardContent>
                  <Box className={classes.linkContainer}>
                    <Box className={classes.cardTitleDescriptionAndHistory}>
                      <Link to={`/devices/${data.macID}/dashboard`} className={classes.cardAnchor}>
                        <Box>
                          <Box className={classes.deviceItemBox}>
                            <Dns className={classes.deviceItemIcon} />
                            <Typography className={classes.deviceName}>{data.name}</Typography>
                          </Box>
                          <Box className={classes.deviceItemBox}>
                            <Fingerprint className={classes.deviceItemIcon} />
                            <Typography className={classes.deviceMacID} color="textSecondary">
                              {data.macID}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>

                    {data.loc ? (
                      <Box className={classes.locationBox}>
                        <LocationOn />
                        <Typography className={classes.deviceLocationText}>{data.loc}</Typography>
                      </Box>
                    ) : null}
                  </Box>
                  <Box className={classes.coordinatesBox}>
                    <Button onClick={() => copyToClipboard(data.coordinates.lat)} className={classes.coordinateButton}>
                      <Box className={classes.coordinatesSingleBox}>
                        <Typography className={classes.smallText}>Lat</Typography>
                        <Typography className={classes.smallText}>{data.coordinates.lat}</Typography>
                      </Box>
                    </Button>
                    <Button onClick={() => copyToClipboard(data.coordinates.lng)} className={classes.coordinateButton}>
                      <Box
                        className={classes.coordinatesSingleBox}>
                        <Typography className={classes.smallText}>Lng</Typography>
                        <Typography className={classes.smallText}>{data.coordinates.lng}</Typography>
                      </Box>
                    </Button>
                  </Box>
                  <Box className={classes.cardButtonBox}>
                    <Box className={classes.locationAndDownloadBox}>
                      <Link to={`/history/${data.macID}`}>
                        <Button color="primary" variant="contained" className={classes.cardButton} disableElevation>
                          <LocationOn />
                        </Button>
                      </Link>
                      <Link to={`/download?id=${data.macID}`}>
                        <Button color="primary" variant="contained" className={classes.cardButton} disableElevation>
                          <GetApp />
                        </Button>
                      </Link>
                    </Box>
                    <Link to={`/devices/${data.macID}/dashboard`}>
                      <Button color="primary" variant="contained" className={classes.cardButton} disableElevation>
                        <ArrowForward />
                      </Button>
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        <Snackbar
          open={Boolean(snackbarOpen)}
          message={snackbarOpen}
          autoHideDuration={1000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          action={
            <IconButton onClick={handleSnackbarClose}>
              <Close color="secondary" />
            </IconButton>
          }
          onClose={handleSnackbarClose}
        />
      </Box>
    </>
  );
}
