import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Card, Typography, MenuItem, Select, Button, Snackbar, Tooltip, TextField, useTheme, useMediaQuery } from '@material-ui/core';
import { FileCopy, ArrowForward } from '@material-ui/icons';
import ReactJSONView from 'react-json-view';
import Navigation from '../../components/Navigation';
import { AuthContext } from '../../components/wrappers/Authentication';
import copyTextToClipboard from '../../tools/functions/copyToClipboard';
import { createNewAccessToken } from '../../tools/functions/firestore';
import EndpointURL from './EndpointURL';
import makeEndpoint from './makeEndpoint';
import conditionalCSS from './styles/index';
import { allEndpoints } from '../../tools/constants';


const TryItAPI = () => {
  // states
  const { details } = useContext(AuthContext);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState('all-devices');
  const [token, setToken] = useState('');
  const [url, setUrl] = useState('');
  const [response, setResponse] = useState();
  const [requiredInputFields, setRequiredInputFields] = useState([]);

  // hooks

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();

  useEffect(() => {
    createNewAccessToken().then((accessToken) => {
      setToken(accessToken);
    });
  }, []);

  // collecting local variables

  const localVariables = useMemo(
    () => ({
      YOUR_USER_ID: details.docId,
    }),
    [details.docId],
  );

  useEffect(() => {
    const endpointDetails = allEndpoints[selectedEndpoint];
    const variables = Object.keys(endpointDetails.variables);
    const endpoint = makeEndpoint(allEndpoints[selectedEndpoint], localVariables);

    for (let i = 0; i < variables.length; i += 1) {
      if (endpointDetails.variables[variables[i]].type === 'input') {
        const varInfo = endpointDetails.variables[variables[i]];

        const inputComponent = (
          <TextField
            variant="outlined"
            size="small"
            placeholder={variables[i]}
            key={`${Math.floor(Math.random() * 100)}_VariableTextField`}
            onChange={(e) => {
              const updatedEndpoint = endpoint.replace(`[${varInfo.name}]`, e.target.value.trim());
              setUrl(updatedEndpoint);
            }}
          />
        );
        setRequiredInputFields((prev) => [...prev, inputComponent]);
      }
    }
    const formattedEndpoint = endpoint
      .split('/')
      .map((urlWord) => {
        if (urlWord.startsWith('[') && urlWord.endsWith(']')) {
          return '';
        }
        return urlWord;
      })
      .join('/');

    setUrl(formattedEndpoint);
    return () => {
      setRequiredInputFields([]);
    };
  }, [selectedEndpoint, localVariables]);

  // functions

  const fireRequest = () => {
    const endpoint = allEndpoints[selectedEndpoint];
    window
      .fetch(url, {
        method: endpoint.method,
        headers: {
          'Content-Type': endpoint.contentType,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((fetchResponse) => fetchResponse.json())
      .then((result) => {
        setResponse(result);
      });
  };

  const copyRequestInformation = () => {
    const endpointDetails = allEndpoints[selectedEndpoint];
    const copyingRequest = `${endpointDetails.method} ${url} ${endpointDetails.http}
Content-Type: ${endpointDetails.contentType}
Authorization: Bearer ${token}`;
    copyTextToClipboard(copyingRequest);
  };

  const closeSnackbar = () => setShowSnackbar(false);

  const openSnackbar = () => setShowSnackbar(true);

  const copyNewAccessToken = () => {
    // create a new access token

    createNewAccessToken().then((accessToken) => {
      copyTextToClipboard(accessToken).then(() => {
        openSnackbar();
      });
    });
  };

  return (
    <>
      <Navigation>
        <Box className={classes.container}>
          <Card className={classes.optionsCard}>
            <Box className={classes.chooseAPIEndpoint}>
              <Typography>Choose Endpoint:</Typography>
              <Select
                variant="outlined"
                value={selectedEndpoint}
                onChange={(e) => {
                  setSelectedEndpoint(e.target.value);
                }}
              >
                <MenuItem value="all-devices">
                  <Tooltip placement="right" title="Get all devices.">
                    <Typography className={classes.menuItemText}>
                      /devices
                    </Typography>
                  </Tooltip>
                </MenuItem>
                <MenuItem value="single-device-data">
                  <Tooltip placement="right" title="Get single device's information.">
                    <Typography className={classes.menuItemText}>
                      /devices/$MAC_ID
                    </Typography>
                  </Tooltip>
                </MenuItem>
                <MenuItem value="location-history">
                  <Tooltip placement="right" title="Get single device's history.">
                    <Typography className={classes.menuItemText}>
                      /history/$MAC_ID
                    </Typography>
                  </Tooltip>
                </MenuItem>
              </Select>
            </Box>
            <Box className={classes.rightArea}>
              <Box className={classes.credentialsBox}>
                <Box className={classes.credentialsItemBox}>
                  <Typography className={classes.credentialsItemTextTitle}>User ID:</Typography>
                  <Typography className={classes.credentialsItemText}>{details && details.docId}</Typography>
                </Box>
                <Box className={classes.credentialsItemBox}>
                  <Typography className={classes.credentialsItemTextTitle}>Access Token:</Typography>
                  <Button variant="contained" disableElevation color="primary" onClick={copyNewAccessToken}>
                    <FileCopy />
                  </Button>
                </Box>
              </Box>
              <Tooltip title="Make Request" placement="top">
                <Button
                  color="primary"
                  onClick={fireRequest}
                  className={classes.makeRequestButton}
                  variant="contained"
                  disableElevation
                >
                  <ArrowForward />
                </Button>
              </Tooltip>
            </Box>
          </Card>
          {requiredInputFields.length ? (
            <Card className={classes.requiredVariableInputCard}>{requiredInputFields}</Card>
          ) : null}
          <EndpointURL url={url} onCopy={copyRequestInformation} />
          {response ? (
            <Card className={classes.responseCard}>
              <ReactJSONView src={response} />
            </Card>
          ) : null}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            message="Copied to clipboard!"
            open={showSnackbar}
            onClose={closeSnackbar}
            autoHideDuration={2000}
          />
        </Box>
      </Navigation >
    </>
  );
};

export default TryItAPI;
