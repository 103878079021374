import { Fab, Tooltip } from '@material-ui/core';
import { GetAppRounded } from '@material-ui/icons';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GraphDataCtx } from '../../wrappers/Contexts/states/GraphDataCtx';
import conditionalCSS from './styles/Download';
import { DevicesListCtx } from '../../wrappers/Contexts/states/DevicesCtx';
import downloadGraph from '../../../tools/functions/downloadGraph';

const Download = () => {
  const classes = conditionalCSS()();
  const { targets } = useContext(GraphDataCtx)
  const { graphData } = useContext(GraphDataCtx);
  const { getSingleDevice } = useContext(DevicesListCtx);
  const { deviceId: mac } = useParams();

  const download = useCallback(() => {
    downloadGraph({
      mac,
      getSingleDevice,
      targets,
      graphData,
    });
  }, [mac, getSingleDevice, targets, graphData]);

  // explicitly rendering two Fab components because of material-ui's tooltip component issue with disabled buttons
  if (!graphData || !graphData.length) {
    return (
      <Fab className={classes.downloadFab} color="primary" disabled>
        <GetAppRounded />
      </Fab>
    );
  }
  return (
    <Tooltip
      title="Download Graph Data"
      aria-label="Download Graph Data"
      placement="left"
      arrow
    >
      <Fab
        className={classes.downloadFab}
        color="primary"
        onClick={download}
      >
        <GetAppRounded />
      </Fab>
    </Tooltip>
  );
};
export default Download;
