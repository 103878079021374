import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import conditionalCSS from './styles/ConnectDeviceSection';

function ConnectDevice() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();
  return (
    <Box className={classes.container}>
      <Box className={classes.leftArea}>
        <Typography variant="h4" className={classes.heading}>Scan Your IoT device</Typography>
        <Typography className={classes.subHeading}>
          Use our android app, scan your IoT device&#39;s Mac ID and get started
          ⚡
        </Typography>
      </Box>
      <Box className={classes.rightArea}>
        <img
          src="/assets/images/eziostat-device.png"
          className={classes.deviceImage}
          alt=""
        />
      </Box>
    </Box>
  );
}

export default ConnectDevice;
