import { makeStyles } from '@material-ui/core';

const conditionalCSS = ({ smDown, xsDown, timeRangeSelectorActive }) => {
  const useStyles = makeStyles(() => ({
    filterPaper: {
      padding: 20,
      display: 'flex',
      alignItems: !smDown && 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: smDown && timeRangeSelectorActive && 'column',
      width: '100%',
      gap: smDown && 20,
    },
    selectBoxes: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
    },
    selectBox: {
      minWidth: 150,
    },
    timeRangeContainer: {
      display: 'flex',
      gap: 10,
      flexDirection: xsDown && 'column',
      flexWrap: 'wrap',
      alignSelf: 'center',
    },
    datePickerBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 130,
    },
    timeSelector: {
      display: 'flex',
      gap: 10,
      flexDirection: xsDown && 'column',
      flexWrap: 'wrap',
    },
    inputPickers: {
      minWidth: 'unset',
    },
    inputPickerInput: {
      textAlign: 'left',
    },
    inputLabel: {
      fontSize: 13,
      marginBottom: 5,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 5,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
