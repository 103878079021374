const getDataPointColorInformation = (value) => {

  if(value <= 50){
    return {
      color: 'rgb(110, 231, 119)',
      contrastText: '#fff',
      label: 'Good',
      max: '50',
    }
  }
  if(value > 50 && value <= 100){
    return {
      color: 'rgb(254, 235, 60)',
      contrastText: '#000',
      label: 'Moderate',
      max: '100'
    }
  }
  if(value > 100 && value <= 150) {
    return {
      color: 'rgb(244, 94, 37)',
      contrastText: '#fff',
      label: 'Poor',
      max: '150'
    }
  }
  if(value > 150 && value <= 200) {
    return {
      color: 'rgb(255, 0, 0)',
      contrastText: '#fff',
      label: 'Unhealthy',
      max: '200'
    }
  }
  if(value > 200 && value <= 300) {
    return {
      color: 'rgb(255, 0, 0)',
      contrastText: '#fff',
      label: 'Very Unhealthy',
      max: '300'
    }
  }
  if(value >= 300){
    return {
      color: 'rgb(98, 56, 42)',
      contrastText: '#fff',
      label: 'Hazardous',
      max: '300+'
    }
  }
  return undefined
};

export default getDataPointColorInformation;