import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../Authentication';
import { firestore } from '../../../../tools/firebase';

export const DevicesListCtx = createContext();

function DevicesList({ children }) {
  const { details } = useContext(AuthContext);
  const [devicesList, setDevicesList] = useState();

  const getSingleDevice = useCallback(async (macID) => {
    const { docId: userID } = details;
    if (Array.isArray(devicesList)) {
      const device = devicesList.filter(
        (eachDevice) => eachDevice.deviceID === macID,
      )[0];
      return device.data;
    }
    const doc = await firestore.collection('users').doc(userID);
    return doc
      .collection('devices')
      .doc(macID)
      .get()
      .then((response) => {
        const deviceData = response.data();

        return (
          deviceData && {
            name: deviceData.name,
            macID: deviceData.mac,
            loc: deviceData.loc,
          }
        );
      });
  }, [details, devicesList]);

  const value = {
    devicesList,
    setDevicesList,
    getSingleDevice,
  };

  return (
    <DevicesListCtx.Provider value={value}>{children}</DevicesListCtx.Provider>
  );
}

DevicesList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DevicesList;
