import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { firebaseAuth } from '../tools/firebase';

function Logout() {
  const [signedOut, setSignedOut] = useState(undefined);

  useEffect(() => {
    firebaseAuth
      .signOut()
      .then(() => {
        setSignedOut(true);
      })
      .catch(() => {
        setSignedOut(false);
      });
  }, []);

  if (signedOut === false) {
    return 'Unable to logout.';
  }
  if (signedOut) {
    return <Redirect to="/" />;
  }
  return null;
}

export default Logout;
