import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import conditionalCSS from './styles/AppDownloadSection';

function AppDownloadSection() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();
  return (
    <Box className={classes.container}>
      <Box className={classes.leftArea}>
        <Typography variant="h4" className={classes.heading}>EzioStat Android App</Typography>
        <Box className={classes.appFeaturesBox}>
          <Typography variant="h6" className={classes.subText}>Connect With App</Typography>
          <Typography variant="h6" className={classes.subText}>Record Data</Typography>
          <Typography variant="h6" className={classes.subText}>See your current air quality in real time!</Typography>
        </Box>
        <a
          href="https://play.google.com/store/apps/details?id=in.aerogram.eziosense.eziostat"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/images/play-badge.png" className={classes.playStoreBadge} alt="" />
        </a>
      </Box>
      <Box>
        <img src="/assets/images/app-login-screen.png" className={classes.appLoginScreenImage} alt="" />
      </Box>
    </Box>
  );
}

export default AppDownloadSection;
