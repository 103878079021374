import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles(() => ({
    deleteButton: {
      background: '#d32f2f',
      color: '#fff',
      '&:hover': {
        background: '#d32f2f',
        color: '#fff',
      },
    },
  }));
  return useStyles;
};
export default conditionalCSS;
