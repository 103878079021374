import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    container: xs ? ({
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      padding: 20,
      background: theme.palette.background.default,
    }) : ({
      display: 'flex',
      justifyContent: 'space-between',
      padding: '2% 5%',
      background: theme.palette.background.default,
    }),
    leftArea: {
      margin: '50px 0px',
    },
    rightArea: {
      display: 'flex',
      alignItems: 'center',
    },
    webAppSubTitle: {
      marginTop: 20,
    },
    webAppSteps: {
      marginTop: 20,
      marginLeft: 10,
    },
    webAppStepsText: {
      margin: 5,
    },
    loginButton: {
      fontSize: 18,
      marginTop: 20,
      marginLeft: 10,
    },
    demoCard: {
      pointerEvents: 'none',
      userSelect: 'none',
      width : xs && '100%',
      maxWidth: xs && 400,
    },
    demoButton: {
      background: theme.palette.primary.main,
      pointerEvents: 'none',
      "&:hover" : {
         background: theme.palette.primary.main,
      }
    }
  }));
  return useStyles;
};
export default conditionalCSS;
