import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import theme from '../../tools/themeConfig';

function MuiWrappers({ children }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>{children}</MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}
MuiWrappers.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MuiWrappers;
