import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import conditionalCSS from './styles/DevicesDataError';

export default function DevicesDataError({ text }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)()

  return (
    <Box className={classes.centerContainer}>
      <Typography color="textSecondary" variant="h2" className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
}

DevicesDataError.propTypes = {
  text: PropTypes.string.isRequired,
};
