import React from 'react';
import Routes from './routes/index';
import Authentication from './components/wrappers/Authentication';
import MuiWrappers from './components/wrappers/MuiWrappers';
import Contexts from './components/wrappers/Contexts';
import Loader from './components/wrappers/Loader';

function App() {
  return (
    <Authentication>
      <MuiWrappers>
        <Contexts>
          <Loader>
            <Routes />
          </Loader>
        </Contexts>
      </MuiWrappers>
    </Authentication>
  );
}

export default App;
