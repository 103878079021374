import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(2),
      transform: 'translateY(-50%)',
      padding: 30,
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    pointBox: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    pointValueBox: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 10,
      width: 30,
      height: 30,
      borderRadius: '50%',
      minWidth: 'unset'
    },
    pointColorBox: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      opacity: '.2',
    },
    pointTextBox: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pointText: {
      fontSize: 32,
      fontWeight: 'bold',
    },
    sensorName: {
      fontSize: 22,
      marginBottom: 10,
    },
    firstRow: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    macIDText: {
      marginTop: 5,
    },
    unitText: {
      width: 'max-content',
      fontSize: 14,
    }
  }));
  return useStyles;
};

export default conditionalCSS;
