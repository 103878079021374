import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import LevelMeter from './LevelMeter';
import conditionalCSS from './styles/visualize'
import { GraphDataCtx } from '../../wrappers/Contexts/states/GraphDataCtx';
import { getDeviceTargetsAndFields } from '../../../tools/functions/firestore'
import { sensors } from '../../../tools/constants';
import { formatToUNIX, readableTimeFormat, subTime } from '../../../tools/functions/timeFormatting';

function FocusedView({ data }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();
  const [selectedField, setSelectedField] = useState();
  const [showingTime, setShowingTime] = useState();
  const { targets, setTargets, fields, setFields } = useContext(GraphDataCtx);
  const { deviceId } = useParams();
  useEffect(() => {
    if (data && data.pm2_5) {
      setSelectedField('pm1_0');
    }
  }, [data])

  useEffect(() => {
    if (!targets) {
      getDeviceTargetsAndFields(deviceId).then((resultTargets, resultFields) => {
        setTargets(resultTargets);
        setFields(resultFields);
      })
    }
  }, [targets, deviceId, setTargets, setFields])

  useEffect(() => {
    let intervalTime = 0
    const updateTime = setInterval(() => {
      intervalTime = 5000;
      if (data && data.createdAt < formatToUNIX(subTime(new Date(), '1 minute'))) {
        setShowingTime(readableTimeFormat(data.createdAt));
      } else {
        setShowingTime(undefined)
      }
    }, intervalTime);
    return () => {
      clearInterval(updateTime)
    }
  }, [data])

  return (
    <>
      <Box className={classes.visualizationContainer}>
        <Box className={classes.sensorButtons}>
          {targets && targets.map((target, index) => {
            if (data && data[target]) {
              return (<Button key={`sensor-button_${index * 36}`} color={selectedField === target ? 'secondary' : 'primary'} disableElevation variant="contained" onClick={() => {
                setSelectedField(target);
              }}>{(fields && fields[index]) ? fields[index] : (sensors[target] || target)}</Button>)
            };
            return null;
          })}

        </Box>
        <Box>
          <LevelMeter value={selectedField ? data[selectedField] : undefined} size="big" />
          {showingTime ?
            <Typography className={classes.updatedAtText}>
              @ {showingTime}
            </Typography> : null
          }

        </Box>
      </Box>
    </>
  )
}
FocusedView.propTypes = {
  data: PropTypes.shape({ pm2_5: PropTypes.number, createdAt: PropTypes.number.isRequired }),
}
FocusedView.defaultProps = {
  data: undefined,
}
export default FocusedView
