import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  formatTime,
  getTodayDate,
  subTime,
} from '../../../../tools/functions/timeFormatting';
import { selectableTimeRanges } from '../../../../tools/constants';

export const FiltersCtx = createContext();

function Filters({ children }) {
  const [selectedFilter, setSelectedFilter] = useState('pm1_0');
  const [selectedTimeRange, selectTimeRange] = useState(
    selectableTimeRanges[4].value,
  );
  const [selectedDate, changeSelectedDate] = useState(
    getTodayDate('yyyy MM dd'),
  );
  const [selectedFromTime, changeSelectedFromTime] = useState(
    subTime(new Date().toISOString(), '1 hour'),
  );
  const [selectedToTime, changeSelectedToTime] = useState(
    formatTime(new Date()),
  );

  const value = {
    selectedFilter,
    setSelectedFilter,
    selectedDate,
    changeSelectedDate,
    selectedFromTime,
    changeSelectedFromTime,
    selectedToTime,
    changeSelectedToTime,
    selectedTimeRange,
    selectTimeRange,
  };

  return <FiltersCtx.Provider value={value}>{children}</FiltersCtx.Provider>;
}

Filters.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Filters;
