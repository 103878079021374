import React, { useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import conditionalCSS from './styles/DeleteSingleDevice';
import { AuthContext } from '../wrappers/Authentication';
import { deleteDevice } from '../../tools/functions/firestore';

function DeleteSingleDevice() {
  const classes = conditionalCSS()();
  const params = useParams();
  const location = useLocation();
  const { details } = useContext(AuthContext);
  const history = useHistory();

  const deleteCurrentDevice = () => {
    deleteDevice(params.deviceId, details.docId).then(() => {
      history.push('/devices');
    });
  };

  if (params.deviceId && location.pathname.startsWith('/devices')) {
    return (
      <>
        <Button className={classes.deleteButton} onClick={deleteCurrentDevice}>
          Delete Device
        </Button>
      </>
    );
  }
  return null;
}

export default DeleteSingleDevice;
