import { CircularProgress, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types';
import conditionalCSS from './styles/levelmeter';

function LevelMeter({ value, size }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = conditionalCSS(xs)();

  if (size === 'big') {
    return (<>

      <Box className={classes.levelMeterContainerBig}>

        <CircularProgress variant="determinate" value={100} color="inherit" className={classes.circularProgressBig} />

        <Typography className={classes.meterValueBig}>
          {value}

        </Typography>

      </Box>

    </>)
  }
  return (
    <Box className={classes.levelMeterContainer}>
      <CircularProgress variant="determinate" value={100} color="inherit" className={classes.circularProgress} />
      <Typography className={classes.meterValue}>
        {value}
      </Typography>
    </Box>
  )
}

LevelMeter.propTypes = {
  value: PropTypes.number,
  size: PropTypes.string,
}
LevelMeter.defaultProps = {
  value: undefined,
  size: 'small'
}
export default LevelMeter
