import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    container: xs ? ({
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      background: theme.palette.background.default,
      padding: 30,
      marginBottom: 20,
    }) : ({
      display: 'flex',
      justifyContent: 'space-between',
      padding: '2% 5%',
      background: theme.palette.background.default,
    }),
    heading: {
      fontSize: xs && 32,
    },
    subHeading: {
      marginTop: 10,
    },
    leftArea: {
      marginTop: xs ? 0 : 50,
    },
    deviceImage: {
      width: 300,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
