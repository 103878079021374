import { makeStyles } from '@material-ui/core';

const conditionalCSS = (xs) => {
  const useStyles = makeStyles((theme) => ({
    visualizationContainer: {
      display: 'flex',
      width: '100%',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      alignItems: 'center',
      justifyContent: xs ? 'center' : 'space-between',
      padding: '30px 5%',
      flexDirection: xs && 'column-reverse',
    },
    sensorButtons: {
      display: xs ? 'grid' : 'flex',
      flexDirection: 'column',
      gap: 10,
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginTop: xs ? '30px' : undefined,
    },
    viewToggleButton: {
      width: 30,
      minWidth: 'unset',
    },
    viewToggleBox: {
      width: 'max-content',
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    activeToggleButton: {
      color: theme.palette.primary.main,
    },
    simultaneousViewContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    simultaneousViewBox: {
      display: 'flex',
      width: '100%',
      height: `100%`,
      padding: '5% 0%',
      gap: 50,
      justifyContent:'center',
      flexWrap: 'wrap'
    },
    simultaneousViewSensorBox: {
      display: 'flex',
      alignItems:'center',
      flexDirection: 'column',
      gap: 10
    },

    lastUpdatedAtBox: { // class referred in SimultaneousView.jsx
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 60,
      marginRight: 20,
    },
    updatedAtText: {
      marginTop: 20,
      textAlign: 'center'
    },
    viewContainer: {
      position: 'relative',
      background: theme.palette.background.default
    },
    fullScreenButton: {
      position: 'absolute',
      top: 10,
      right: 20,
      zIndex: 10000,
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
  }));
  return useStyles;
};

export default conditionalCSS;
