import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { AuthContext } from '../wrappers/Authentication';
import conditionalCSS from './styles/Menu';
import Accounts from './Accounts';
import DeleteSingleDevice from './DeleteSingleDevice';

export default function Menu() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { authenticated, state, details } = useContext(AuthContext);
  const classes = conditionalCSS()();
  const location = useLocation();
  return (
    <Box className={classes.menu}>
      {location.pathname !== '/devices' && location.pathname !== '/devices/' ? (
        <>
          {authenticated ? (
            <>
              {
                !xs ? <>
                  <Link to="/devices" className={classes.anchorBtn} tabIndex="-1">
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      disableElevation
                    >
                      My Devices
                    </Button>
                  </Link>
                  <DeleteSingleDevice />
                </> : null
              }
            </>
          ) : (
            <Link to="/login" className={classes.anchorBtn} tabIndex="-1">
              <Button
                color="inherit"
                variant="contained"
                className={classes.button}
                disableElevation
                disabled={state === 'loading'}
              >
                Login
              </Button>
            </Link>
          )}
        </>
      ) : null}
      <Accounts userDetails={details} />
    </Box>
  );
}
