import React, { useCallback, useEffect, useRef, useContext, useState } from 'react';
import {
  GoogleMap as ReactGoogleMaps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Box } from '@material-ui/core';
import conditionalCSS from '../../pages/LiveTracker/styles/MapStyles'
import Options from './Options';
import { MapStateContext } from '../wrappers/Contexts/states/MapStateCtx';
import getDataPointColorInformation from '../../tools/functions/getDataPointColorInformation';
import DataPointToolBox from './DataPointToolBox';
import { DevicesListCtx } from "../wrappers/Contexts/states/DevicesCtx";
import { AuthContext } from "../wrappers/Authentication";
import { getAllDevices, liveDataPointListener } from '../../tools/functions/firestore';


function Map() {
  const mapRef = useRef();
  const classes = conditionalCSS()();
  const { devicesList, setDevicesList } = useContext(DevicesListCtx);
  const { details } = useContext(AuthContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GCP_API,
  });

  const { setZoom, zoom, map, setMap, currentCoords, setCurrentCoords } = useContext(MapStateContext);
  const [point, setPoint] = useState();
  const [dataPoints, setDataPoints] = useState();

  // const [dataPointsList, setDataPointsList] = useState([]);


  // functions

  const onLoad = useCallback(
    (loadedMap) => {
      const bounds = new window.google.maps.LatLngBounds();

      loadedMap.fitBounds(bounds);

      setMap(loadedMap);

    }, [setMap]);
  const onUnmount = useCallback(() => { }, []);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentCoords(position.coords)
      })
    }
  }, [setCurrentCoords])

  // events

  useEffect(() => {
    if (map) {
      window.google.maps.event.addListener(map, 'zoom_changed', () => {
        const mapZoom = map.getZoom();
        setZoom(mapZoom);
      })
    }
  }, [map, setZoom])

  useEffect(() => {
    if (currentCoords && map) {
      map.panTo({ lat: currentCoords.latitude, lng: currentCoords.longitude });
    }
  }, [map, currentCoords])

  useEffect(() => {
    if (devicesList === undefined) {
      getAllDevices(details).then((result) => {
        setDevicesList(result)
      })
    }
  }, [devicesList, setDevicesList, details])


  useEffect(() => {
    liveDataPointListener({ userId: details.docId, devices: devicesList, email: details.email }, (information) => {
      setDataPoints(information);
    });
  }, [devicesList, details])


  if (isLoaded) {

    return (
      <>
        <Box className={classes.mapContainer} ref={mapRef}>
          <Box className={classes.mapBox}>
            <ReactGoogleMaps mapContainerClassName={classes.map} onLoad={onLoad} onUnmount={onUnmount} options={{
              streetViewControl: false,
              zoomControl: false,
              fullscreenControl: false,
              zoom,
            }}
            >

              {dataPoints ? dataPoints.map((value, index) => {

                const openTooltip = () => {
                  setPoint(value)
                };

                return (
                  <Marker
                    onClick={openTooltip}
                    key={`${index * 3}_marker`}

                    position={
                      {
                        lat: value.lat,
                        lng: value.lng
                      }
                    }
                    icon={
                      {
                        url: `/assets/images/Pollution Level Colors/${getDataPointColorInformation(value.pm2_5).label}.png`
                      }
                    }
                    label={zoom >= 18 ? ({
                      text: value.pm2_5.toString(),
                      color: getDataPointColorInformation(value.pm2_5).contrastText
                    }) : undefined}
                    zIndex={index}
                  />
                )
              }) : null}

            </ReactGoogleMaps>
          </Box>
          <Options mapElement={mapRef.current} />
          <DataPointToolBox point={point} />
        </Box>
      </>
    )
  }
  return null
}


export default Map;
