import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from './Authentication';

const PrivateRoute = ({ ...props }) => {
  const { state } = useContext(AuthContext);
  switch (state) {
    case 'authenticated':
      return <Route {...props} />;
    case 'unauthenticated':
      return <Redirect to="/login" />;
    default:
      return null;
  }
};

export default PrivateRoute;
