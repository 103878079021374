import { firebaseAuth, firestore } from '../firebase';
import { formatToUNIX } from './timeFormatting';

export const getGraphData = ({ localFromTime, localToTime, mac }) =>
  new Promise((resolve, reject) => {

    const formattedFrom = formatToUNIX(localFromTime);
    const formattedTo = formatToUNIX(localToTime);

    firestore
      .collection('devices')
      .doc(mac)
      .collection('data')
      .where('createdAt', '>=', formattedFrom)
      .where('createdAt', '<=', formattedTo)
      .orderBy('createdAt', 'asc')
      .get()
      .then((snapshot) => {
        // mapping firestore documents into javascript array.
        const graphData = snapshot.docs.map((doc) => {
          const data = doc.data();
          return data;
        });
        // retrieving sensor types
        firestore.collection('devices').doc(mac).get().then((deviceSnapshot) => {
          const snapshotData = deviceSnapshot.data();
          if(snapshotData){
            const {targets, fields} = snapshotData;
            const data = {targets, fields, data: graphData};
            resolve(data);
          }
          resolve()
        })

      })
      .catch(reject);
  });

export const getAllDevices = (details) => new Promise((resolve, reject) => {
  (async () => {
    const doc = await firestore.collection('users').doc(details.docId);
    doc
      .collection('devices')
      .get()
      .then((devicesListData) => {
        
        const devices = [];

        devicesListData.forEach((device) => {
            const deviceData = device.data();
            const {mac, name, loc, lat, lng} = deviceData;

            devices.push({
              deviceID: mac,
              data: {
                name,
                macID: mac,
                loc,
                coordinates: {
                  lat,
                  lng
                }
              },
            });
          });
        resolve(devices);
      })
      .catch(reject);
  })();
});

export const getDeviceTargetsAndFields = (mac) => new Promise((resolve, reject) => {
  firestore.collection('devices').doc(mac).get().then((deviceSnapshot) => {
    const {targets, fields} = deviceSnapshot.data();
    resolve(targets, fields);
  }).catch(reject)
});
export const deleteDevice = (deviceId, userId) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('users')
      .doc(userId)
      .collection('devices')
      .doc(deviceId)
      .delete()
      .then(() => {
        firestore
          .collection('reg_eziostat_devices')
          .doc(deviceId)
          .collection('users')
          .doc(userId)
          .delete()
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });

export const getDeviceLocations = (macID) =>
  new Promise((resolve, reject) => {
    firestore
      .collection('devices')
      .doc(macID)
      .collection('loc_history')
      .orderBy('createdAt', 'desc')
      .get()
      .then((snapshot) => resolve(snapshot.docs.map((doc) => ({...doc.data(), docId: doc.id}))))
      .catch(reject);
  });

export const getDeviceDefaultLocationId = ({userId, macId}) =>
  new Promise((resolve, reject) => {
    firestore
    .collection('users')
    .doc(userId)
    .collection('devices')
    .doc(macId)
    .get()
    .then((fields) => {
      const {default_loc: defaultLoc} = fields.data();
      resolve(defaultLoc)
    })
    .catch(reject);
});

export const setDeviceDefaultLocationId = (userID, macID, documentId) =>
  new Promise((resolve, reject) => {
    firestore
    .collection('users')
    .doc(userID)
    .collection('devices')
    .doc(macID)
    .update({
      default_loc: documentId,
    })
    .then(() => {
      resolve()
    })
    .catch(reject);
});


export const createNewAccessToken = () =>
  new Promise((resolve, reject) => {
    firebaseAuth.currentUser.getIdToken(true).then(resolve).catch(reject);
  });



// snapshot listener


export const devicesSnapshotListener = (macID, onUpdate) => {

  firestore.collection('devices').doc(macID).collection('data').orderBy('createdAt', 'desc').limit(1).onSnapshot(snapshot => {
    const changes = snapshot.docChanges();
    if(changes[0]) onUpdate(changes[0].doc.data())
  })
}

export const liveDataPointListener = ({userId, devices, email}, onUpdate) => {

  const devicesDataPoints = [];


  if(devices) {
    devices.forEach((device) => {


      const fetchDataPoints = ({lat, lng}) => {
        firestore.collection('devices').doc(device.deviceID).collection('data').orderBy('createdAt', 'desc').limit(1).onSnapshot(snapshot => {
          const changes = snapshot.docChanges();
          if(changes[0]){
            const data = changes[0].doc.data();
            
            const pointIndex = devicesDataPoints.findIndex((value) => value.id === device.deviceID)
            
            if(pointIndex === -1){
              devicesDataPoints.push({id: device.deviceID, pm2_5: data.pm2_5, lat, lng})
            } else {
              devicesDataPoints[pointIndex].pm_25 = data.pm2_5;
              devicesDataPoints[pointIndex].lat = lat;
              devicesDataPoints[pointIndex].lng = lng;
            }
            return onUpdate(devicesDataPoints);
          } 
            return undefined
          
        })
      } 
      

      firestore.collection('users').doc(userId).collection('devices').doc(device.deviceID).get().then((defaultLocSnapshot) => {
        const {default_loc: defaultLoc} = defaultLocSnapshot.data();
        if(defaultLoc){
          firestore.collection('devices').doc(device.deviceID).collection('loc_history').doc(defaultLoc).get().then((dataSnapshot) => {
            const {lat, lng} = dataSnapshot.data();
            fetchDataPoints({lat, lng});
          })
        } else {
          firestore.collection('devices').doc(device.deviceID).collection('loc_history').where('email', '==', email).get().then((documentSnapshot) => {
            const docs = documentSnapshot.docs.map((doc) => doc.data()).sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
            const {lat, lng} = docs[0];
            fetchDataPoints({lat, lng})
          })
        }
      })


    })
  }
}


export default undefined;
