import { makeStyles } from '@material-ui/core';

const conditionalCSS = () => {
  const useStyles = makeStyles(() => ({
    accountsMenu: {
      minWidth: 350,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuBox: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    userProfilePhoto: {
      width: 100,
      height: 100,
      borderRadius: '50%',
      marginBottom: 20,
    },
    userEmail: {
      fontSize: 14,
    },
    logoutButton: {
      marginTop: 10,
    },
  }));
  return useStyles;
};
export default conditionalCSS;
